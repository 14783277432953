import React, { ChangeEvent, FC, ReactElement, useContext, useEffect, useState } from 'react'
import {
  createStyles,
  Grid,
  Hidden,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core'
import GetAppIcon from '@material-ui/icons/GetApp'
import { useTranslation } from 'react-i18next'
import {
  PageTransitionSearchListDto,
  TransitionCustomsInfoSearchListDto,
  TransitionCustomsInfoSearchListDtoStateEnum,
  TransitionSearchListDtoDirectionEnum,
  TransitionSearchListDtoReceiptMethodEnum,
  TransitionSearchListSearchDto,
} from '../../api'
import { cleanObject } from '../../utils/utils'
import transitionService from '../../services/TransitionService'
import { Order } from '../../enums/Order'
import TransitionSearchFilter from './TransitionSearchFilter'
import ConardButton from '../../components/ConardButton'
import { useHistory } from 'react-router-dom'
import FilterListIcon from '@material-ui/icons/FilterList'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { RootState, useAppDispatch } from '../../redux/store'
import {
  setTransition,
  setTransitionFormDisabled,
  setTransitionPositionDisabled,
  setTransitionSaveDisabled,
  transitionInitialState,
} from '../../redux/transition/transitionSlice'
import { format } from 'date-fns'
import { LocalShipping, Train } from '@material-ui/icons'
import { useSelector } from 'react-redux'
import PolicyOutlinedIcon from '@material-ui/icons/PolicyOutlined'
import ProcessedFileService from '../../services/ProcessedFileService'
import ConardProgressBar from '../../components/ConardProgressBar'
import ConardTooltip from '../../components/ConardTooltip'
import { TransitionMode } from '../../enums/TransitionMode'
import { ConardCoordinates } from '../../components/ConardCoordinates'
import ConardTransitionSearchIcon from '../../components/ConardTransitionSearchIcon'
import { StickyNote2Outlined } from '@mui/icons-material'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import { setTransitionSearchListSearch } from '../../redux/transition/TransitionSearchListSearch'
import { ConfigContext } from '../../context/ConfigContext'
import { AxiosError } from 'axios'

const useStyles = makeStyles((theme) =>
  createStyles({
    gridContainer: {
      marginTop: '8vh',
    },
    detailButton: {
      width: '7vw',
    },
    filterButton: {
      textAlign: 'center',
      margin: '0 20px ',
    },
    t1Typography: {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      fontFamily: 'Arial',
    },
    noteIcon: {
      color: theme.palette.primary.main,
    },
    adrRidIcon: {
      color: theme.palette.warning.main,
    },
    temporaryCustomer: {
      color: theme.palette.warning.main,
    },
    colorDiv: {
      width: '10px',
      height: '10px',
      borderRadius: '100%',
      marginLeft: '10px',
    },
    companyCell: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
  })
)

interface HeadCell {
  id: string
  label: string | ReactElement
}

const TransitionSearchListPage: FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles()

  const dispatch = useAppDispatch()

  const { adrReport, englishExcelExport } = useContext(ConfigContext)

  const [pageTransitions, setPageTransitions] = useState<PageTransitionSearchListDto | null>()
  const transitions = useSelector<RootState, TransitionSearchListSearchDto | undefined>(
    (state) => state.transitionSearchListSearch.transitionSearch
  )

  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(150)
  const [order, setOrder] = useState<Order>(Order.Desc)
  const [orderBy, setOrderBy] = useState<string>('transitionDateTime')
  const [showFilter, setShowFilter] = useState(true)
  const [blockSearch, setBlockSearch] = useState<boolean>(true)

  const headCells: HeadCell[] = [
    { id: 'note', label: <StickyNote2Outlined fontSize="small" /> },
    { id: 'direction', label: t('pages.gateIn.form.direction.label') },
    {
      id: 'receiptMethod',
      label: (
        <>
          <Train fontSize="small" />
          <Typography variant="h5">/</Typography>
          <LocalShipping fontSize="small" />
        </>
      ),
    },
    { id: 'iluCode', label: t('pages.gateIn.form.iluCode.label') },
    { id: 'containerState', label: t('pages.gateIn.form.state.label') },
    { id: 'companyName', label: t('entity.company.fields.name') },
    { id: 'shipownerName', label: t('pages.gateIn.form.shipowner.label') },
    { id: 'semitrailerLicencePlate', label: t('pages.gateIn.form.semitrailerLicencePlate.label') },
    {
      id: 'repleted',
      label: t('pages.gateIn.form.repleted.checked') + ' / ' + t('pages.gateIn.form.repleted.unchecked'),
    },
    { id: 'truckLicencePlate', label: t('pages.gateIn.form.truckLicencePlate.label') },
    { id: 'transitionDateTime', label: t('pages.gateIn.form.transitionDatetime.label') },
    { id: 'sectorLetter,sectorColumn,sectorRow,sectorFloor', label: t('pages.gateIn.form.location.label') },
    { id: 'damaged', label: '' },
    { id: 'transitionCustomsInfo.state', label: <PolicyOutlinedIcon fontSize="small" /> },
    { id: 'adrRid', label: t('pages.gateIn.form.adrRid.label') },
  ]

  useEffect(() => {
    search(transitions, false)
  }, [page, pageSize, orderBy, order]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSort = (property: string) => () => {
    const isAsc = orderBy === property && order === Order.Asc
    setOrder(isAsc ? Order.Desc : Order.Asc)
    setOrderBy(property)
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
    setPage(page)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPageSize(Number.parseInt(event.target.value))
    setPage(0)
  }

  const searchFiltered = (data: TransitionSearchListSearchDto | undefined) => {
    search(data, true)
  }

  const search = (data: TransitionSearchListSearchDto | undefined, fromFilter: boolean) => {
    setBlockSearch(true)
    dispatch(setTransitionSearchListSearch(data))

    const dateFrom: Date | undefined = data?.transitionDateTimeFrom ? new Date(data?.transitionDateTimeFrom) : undefined
    const dateTo: Date | undefined = data?.transitionDateTimeTo ? new Date(data?.transitionDateTimeTo) : undefined

    data = {
      ...data,
      container: {
        ...cleanObject(data?.container),
        shipowner: {
          ...cleanObject(data?.container?.shipowner),
        },
        company: {
          ...cleanObject(data?.container?.company),
        },
      },
      containerStates: data?.containerStates ? data?.containerStates.filter((state) => !!state) : [],
      transitionDateTimeFrom: dateFrom?.toISOString(),
      transitionDateTimeTo: dateTo?.toISOString(),
    }

    let currentSort: Array<string> | undefined = undefined
    if (orderBy !== undefined) {
      const currentOrder: string = order !== undefined ? order : 'asc'
      currentSort = [orderBy + ',' + currentOrder]
    }

    transitionService
      .searchForList(page, pageSize, currentSort, cleanObject(data))
      .then((response) => {
        setPageTransitions(response.data)
        if (fromFilter) {
          setPage(0)
        }
      })
      .catch()
      .finally(() => {
        setBlockSearch(false)
      })
  }

  const downloadExport = async (language: 'ENGLISH' | 'CZECH') => {
    const exportType = 'STANDARD'
    const data = {
      ...transitions,
      container: {
        ...cleanObject(transitions?.container),
        shipowner: {
          ...cleanObject(transitions?.container?.shipowner),
        },
        company: {
          ...cleanObject(transitions?.container?.company),
        },
      },
    }

    try {
      const response = await transitionService.downloadExport(exportType, language, cleanObject(data))
      const url = window.URL.createObjectURL(response.data)
      const a = document.createElement('a')
      a.href = url
      a.download = 'transitionExport.xlsx'
      a.click()
    } catch (err: unknown) {
      if (err instanceof Error && 'response' in err) {
        const axiosError = err as AxiosError
        if (axiosError.response && axiosError.response.status === 413) {
          console.error('Request Entity Too Large', axiosError)
        } else {
          console.error('Error while downloading export:', axiosError)
        }
      } else {
        console.error(err)
      }
    }
  }

  const downloadAdrExport = async () => {
    const exportType = 'ADR_RID'
    const data = {
      ...transitions,
      container: {
        ...cleanObject(transitions?.container),
        shipowner: {
          ...cleanObject(transitions?.container?.shipowner),
        },
        company: {
          ...cleanObject(transitions?.container?.company),
        },
      },
    }

    try {
      const response = await transitionService.downloadExport(exportType, 'CZECH', cleanObject(data))
      const url = window.URL.createObjectURL(response.data)
      const a = document.createElement('a')
      a.href = url
      a.download = 'ADRExport.xlsx'
      a.click()
    } catch (err: unknown) {
      if (err instanceof Error && 'response' in err) {
        const axiosError = err as AxiosError
        if (axiosError.response && axiosError.response.status === 413) {
          console.error('Request Entity Too Large', axiosError)
        } else {
          console.error('Error while downloading export:', axiosError)
        }
      } else {
        console.error(err)
      }
    }
  }

  const onDetail = (id: number | undefined, direction: TransitionSearchListDtoDirectionEnum | undefined) => {
    dispatch(setTransitionFormDisabled(true))
    dispatch(setTransitionPositionDisabled(true))
    dispatch(setTransitionSaveDisabled(true))
    dispatch(setTransition(transitionInitialState.transition))

    if (direction === TransitionSearchListDtoDirectionEnum.Extern) {
      const searchParams = new URLSearchParams({ id: id?.toString() ?? '' })
      history.push(`/extern?${searchParams}`)
    } else {
      history.push(`/search/transition/detail/${id}`)
    }
  }

  const handleTrainDetailClick = (seatedTrainId: number | undefined, unseatedId: number | undefined) => {
    history.push(`/search/train/detail/${!!seatedTrainId ? seatedTrainId : unseatedId!}`)
  }

  const getCustomsStateIcon = (customsState: TransitionCustomsInfoSearchListDtoStateEnum | undefined) => {
    switch (customsState) {
      case undefined: {
        return ''
      }
      case TransitionCustomsInfoSearchListDtoStateEnum.Stop: {
        return (
          <ConardTooltip title={t('pages.customs.table.customsState.false')}>
            <PolicyOutlinedIcon color="error" />
          </ConardTooltip>
        )
      }
      case TransitionCustomsInfoSearchListDtoStateEnum.T1ByEmail:
      case TransitionCustomsInfoSearchListDtoStateEnum.T1ByUser: {
        return (
          <ConardTooltip
            title={`${t('pages.customs.table.customsState.true')} - ${t(
              'enum.transitionCustomsState.' + customsState
            )}`}
          >
            <Typography className={classes.t1Typography}>{t('enum.transitionCustomsState.T1_BY_USER')}</Typography>
          </ConardTooltip>
        )
      }
      default: {
        return (
          <ConardTooltip
            title={`${t('pages.customs.table.customsState.true')} - ${t(
              'enum.transitionCustomsState.' + customsState
            )}`}
          >
            <PolicyOutlinedIcon color="primary" />
          </ConardTooltip>
        )
      }
    }
  }

  const getCustomsState = (customsInfo: TransitionCustomsInfoSearchListDto | undefined) => {
    if (!!customsInfo?.processedFile) {
      return (
        <IconButton onClick={() => ProcessedFileService.handleDownload(customsInfo?.processedFile)}>
          <ConardTooltip placement="top" title={t('any.buttons.customsPDF.download') ?? ''}>
            {getCustomsStateIcon(customsInfo?.state)}
          </ConardTooltip>
        </IconButton>
      )
    }
    return getCustomsStateIcon(customsInfo?.state)
  }

  return (
    <>
      <Typography variant="h4" color="primary">
        {t('pages.searchTransition.title')}
      </Typography>
      <Grid container className={classes.gridContainer} direction="row" alignItems="center" spacing={3}>
        <Grid container>
          <Grid item xl={2} lg={3} md={6} sm={6} xs={6}>
            <div className={classes.filterButton}>
              <ConardButton
                conardVariant="transparent"
                startIcon={<FilterListIcon />}
                endIcon={showFilter ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                onClick={() => setShowFilter(!showFilter)}
              >
                {t('any.buttons.filter')}
              </ConardButton>
            </div>
          </Grid>
          <Grid item xl={2} lg={3} md={6} sm={6} xs={6}>
            <div className={classes.filterButton}>
              <ConardButton
                conardVariant="transparent"
                startIcon={<GetAppIcon />}
                onClick={() => downloadExport('CZECH')}
              >
                {englishExcelExport ? t('any.buttons.exportCzech') : t('any.buttons.export')}
              </ConardButton>
            </div>
          </Grid>
          {englishExcelExport && (
            <Grid item xl={2} lg={3} xs={6}>
              <div className={classes.filterButton}>
                <ConardButton
                  conardVariant="transparent"
                  startIcon={<GetAppIcon />}
                  onClick={() => downloadExport('ENGLISH')}
                >
                  {t('any.buttons.exportEnglish')}
                </ConardButton>
              </div>
            </Grid>
          )}
          {adrReport && (
            <Grid item xl={2} lg={3} md={6} sm={6} xs={6}>
              <div className={classes.filterButton}>
                <ConardButton conardVariant="orange" startIcon={<GetAppIcon />} onClick={downloadAdrExport}>
                  {t('any.buttons.exportADR')}
                </ConardButton>
              </div>
            </Grid>
          )}
        </Grid>
        <Hidden mdDown>
          <Grid item xl={8} lg={6}></Grid>
        </Hidden>

        {showFilter && (
          <TransitionSearchFilter
            searchFilter={searchFiltered}
            transitionMode={TransitionMode.SEARCH}
            blockSearch={blockSearch}
          />
        )}

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Paper variant="outlined">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'asc'}
                          onClick={handleSort(headCell.id)}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pageTransitions?.content?.map((transition) => (
                    <TableRow key={transition.id}>
                      <TableCell component="td" scope="row">
                        {transition.note ? (
                          <ConardTooltip title={transition.note}>
                            <StickyNote2Outlined className={classes.noteIcon} />{' '}
                          </ConardTooltip>
                        ) : (
                          ''
                        )}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {t('enum.direction.' + transition.direction)}
                      </TableCell>
                      <TableCell component="td" scope="row" align="center">
                        {transition.receiptMethod === TransitionSearchListDtoReceiptMethodEnum.Road ? (
                          <ConardTooltip title={t('pages.gateIn.form.receiptMethod.unchecked')}>
                            <LocalShipping color="primary" />
                          </ConardTooltip>
                        ) : (
                          <ConardTooltip
                            title={
                              t('pages.gateIn.form.receiptMethod.checked') +
                              (transition.trainName ? ` - ${transition.trainName}` : '')
                            }
                          >
                            <IconButton
                              onClick={() =>
                                handleTrainDetailClick(transition?.seatedTrainId, transition.unseatedTrainId)
                              }
                            >
                              <Train color="primary" />
                            </IconButton>
                          </ConardTooltip>
                        )}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {transition.iluCode}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {t('enum.containerState.' + transition.containerState)}
                      </TableCell>
                      <TableCell
                        component="td"
                        scope="row"
                        className={transition.customerName ? classes.temporaryCustomer : ''}
                      >
                        {transition.customerName ? (
                          <ConardTooltip title={t('pages.gateIn.form.temporaryCustomer.tooltip')}>
                            <div className={classes.companyCell}>
                              {transition.customerName}
                              <div style={{ backgroundColor: transition.customerColor }} className={classes.colorDiv} />
                            </div>
                          </ConardTooltip>
                        ) : (
                          <div className={classes.companyCell}>
                            {transition.companyName}
                            <div style={{ backgroundColor: transition.companyColor }} className={classes.colorDiv} />
                          </div>
                        )}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {transition.shipownerName}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {transition.semitrailerLicencePlate}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {transition.repleted
                          ? t('pages.gateIn.form.repleted.checked')
                          : t('pages.gateIn.form.repleted.unchecked')}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {transition.truckLicencePlate}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {transition.transitionDateTime
                          ? format(new Date(transition.transitionDateTime), 'dd.MM.yyyy HH:mm')
                          : ''}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        <ConardCoordinates transition={transition} />
                      </TableCell>
                      <TableCell component="td" scope="row">
                        <ConardTransitionSearchIcon
                          transitionSearchListDto={{
                            containerType: transition.containerType,
                            semitrailerType: transition.semitrailerType,
                            shippingContainerType: transition.shippingContainerType,
                          }}
                          containerType={transition.containerType}
                          damaged={transition.damaged ?? false}
                        />
                      </TableCell>
                      <TableCell component="td" scope="row" align="center">
                        {getCustomsState(transition.transitionCustomsInfo)}
                      </TableCell>
                      <TableCell component="td" scope="row" align="left">
                        {transition.adrRid && (
                          <ConardTooltip title={t('pages.gateIn.form.adrRid.label')}>
                            <WarningAmberOutlinedIcon className={classes.adrRidIcon} />
                          </ConardTooltip>
                        )}
                      </TableCell>
                      <TableCell component="td" className={classes.detailButton}>
                        <ConardButton
                          conardVariant="light"
                          onClick={() => onDetail(transition.id, transition.direction)}
                        >
                          {t('any.buttons.detail')}
                        </ConardButton>
                      </TableCell>
                    </TableRow>
                  ))}

                  {pageTransitions?.content && pageTransitions?.totalElements === 0 && (
                    <TableRow key="noData">
                      <TableCell component="th" scope="row" align="center" colSpan={16}>
                        {t('pages.common.table.noData')}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <ConardProgressBar showBar={!pageTransitions} />
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50, 150]}
              component="div"
              count={pageTransitions?.totalElements ?? 0}
              rowsPerPage={pageSize}
              labelRowsPerPage={t('pages.common.pagination.rowsPerPage')}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}

export default TransitionSearchListPage
