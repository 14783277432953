import React, { ChangeEvent, FC, useContext, useEffect, useState } from 'react'
import { AccordionDetails } from '@mui/material'
import { Grid, InputAdornment, makeStyles, MenuItem } from '@material-ui/core'
import { ConardAccordion, ConardAccordionSummary } from '../../../components/transition/ConardAccordion'
import shipownerService from '../../../services/ShipownerService'
import { CompanyDto, ContainerDto, ContainerDtoContainerTypeEnum, ShipownerDto, UserDtoRoleEnum } from '../../../api'
import ConardSelect from '../../../components/ConardSelect'
import { ConardLabel } from '../../../components/transition/DriverFields'
import ConardTextField from '../../../components/ConardTextField'
import { useTranslation } from 'react-i18next'
import companyService from '../../../services/CompanyService'
import { ContainerTypeField } from './ContainerTypeField'
import { ContainerProperties } from './ContainerProperties'
import { FormViewMode } from '../../../enums/FormViewMode'
import { useFormContext } from 'react-hook-form'
import { KioskLabel } from '../../kiosk/shared/KioskLabel'
import { KioskAccordionSummary } from '../../kiosk/shared/KioskAccordion'
import { ConfigContext } from '../../../context/ConfigContext'
import { useConardAuth } from '../../../hooks/useConardAuth'

const useStyles = makeStyles({
  gridItem: {
    width: '100%',
  },
  hidden: {
    display: 'none',
  },
})

interface ContainerBoxProps {
  container: ContainerDto | undefined
  formMode: FormViewMode
}

export const ContainerBox: FC<ContainerBoxProps> = ({ container, formMode }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { control, errors } = useFormContext()
  const { heavyTested } = useContext(ConfigContext)
  const { getRole } = useConardAuth()

  const [shipowners, setShipowners] = useState<ShipownerDto[]>([])
  const [parentCompanies, setParentCompanies] = useState<CompanyDto[]>([])
  const [companies, setCompanies] = useState<CompanyDto[]>([])

  const [selectedShipowner, setSelectedShipowner] = useState<number | ''>('')
  const [selectedParentCompany, setSelectedParentCompany] = useState<number | ''>('')
  const [selectedCompany, setSelectedCompany] = useState<number | ''>('')

  const getShipowners = () => {
    shipownerService.findAll(undefined, undefined, undefined).then((response) => {
      setShipowners(response.data.content ?? [])
    })
  }

  const fieldDisabled = <K extends keyof ContainerDto>(field: K) => {
    return formMode !== FormViewMode.EXTERN && !!container?.[field]
  }

  const getParentCompanies = () => {
    companyService.findAllParentCompanies().then((response) => {
      setParentCompanies(response.data.content ?? [])
    })
  }

  const getCompanies = () => {
    companyService.findAllCompanies().then((response) => {
      setCompanies(response.data.content ?? [])
    })
  }

  useEffect(() => {
    getShipowners()
    getParentCompanies()
    getCompanies()

    setSelectedShipowner(container?.shipowner?.id ?? '')
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleShipownerChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSelectedShipowner(+event.target.value)
  }

  const handleParentCompanyChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSelectedParentCompany(+event.target.value)
  }

  const handleCompanyChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSelectedCompany(+event.target.value)
  }

  useEffect(() => {
    if (!!container?.company) {
      setSelectedCompany(container.company?.id ?? '')
      control.setValue('container.company.id', container.company?.id ?? '')
    } else {
      const companyId = shipowners.find(
        (shipowner) => shipowner.id === control.getValues('container.shipowner.id')
      )?.companyId
      setSelectedCompany(companyId ?? '')
      control.setValue('container.company.id', companyId ?? '')
    }
  }, [selectedShipowner, container, control, shipowners])

  useEffect(() => {
    const parentCompanyId = companies.find(
      (company) => company.id === control.getValues('container.company.id')
    )?.parentCompanyId
    setSelectedParentCompany(parentCompanyId ?? '')
    control.setValue('container.company.parentCompanyId', parentCompanyId ?? '')
  }, [selectedCompany, control, companies])

  const shortenName = (name: string) => {
    return name.length > 24 ? name.slice(0, 24).concat('...') : name
  }

  if (shipowners.length === 0 || parentCompanies.length === 0 || companies.length === 0) {
    //TODO add some skeleton loader
    return <div />
  }

  return (
    <div>
      <ConardAccordion defaultExpanded={UserDtoRoleEnum.Driver !== getRole() || !container?.company}>
        {formMode === FormViewMode.KIOSK ? (
          <KioskAccordionSummary>
            {container?.containerType === ContainerDtoContainerTypeEnum.ShippingContainer
              ? t('form.containerForm.title.shippingContainer')
              : t('form.containerForm.title.semitrailer')}
          </KioskAccordionSummary>
        ) : (
          <ConardAccordionSummary>
            {container?.containerType === ContainerDtoContainerTypeEnum.ShippingContainer
              ? t('form.containerForm.title.shippingContainer')
              : t('form.containerForm.title.semitrailer')}
          </ConardAccordionSummary>
        )}
        <AccordionDetails>
          <Grid container spacing={5}>
            <Grid item>
              <Grid container spacing={2}>
                {container?.containerType === ContainerDtoContainerTypeEnum.ShippingContainer && (
                  <Grid item className={classes.gridItem}>
                    {formMode === FormViewMode.KIOSK ? (
                      <KioskLabel inactive={fieldDisabled('shipowner')}>{t('form.containerForm.shipowner')}</KioskLabel>
                    ) : (
                      <ConardLabel inactive={fieldDisabled('shipowner')}>
                        {t('form.containerForm.shipowner')}
                      </ConardLabel>
                    )}
                    <ConardSelect
                      large
                      roundedSquare
                      name="container.shipowner.id"
                      control={control}
                      disabled={fieldDisabled('shipowner')}
                      defaultValue=""
                      value={selectedShipowner}
                      error={!!errors.container?.shipowner?.id}
                      helperText={errors.container?.shipowner?.id?.message ?? ''}
                      onChange={(event) => handleShipownerChange(event)}
                    >
                      {Object.values(shipowners).map((shipowner) => (
                        <MenuItem key={shipowner.id} value={shipowner.id}>
                          {shortenName(shipowner.name)}
                        </MenuItem>
                      ))}
                    </ConardSelect>
                  </Grid>
                )}

                <Grid
                  item
                  className={
                    new Set([FormViewMode.DRIVER, FormViewMode.KIOSK]).has(formMode) ? classes.hidden : classes.gridItem
                  }
                >
                  <ConardLabel inactive={fieldDisabled('company')}>{t('form.containerForm.parentCompany')}</ConardLabel>
                  <ConardSelect
                    roundedSquare
                    name="container.company.parentCompanyId"
                    control={control}
                    defaultValue=""
                    value={selectedParentCompany}
                    disabled={fieldDisabled('company')}
                    error={!!errors.container?.company?.parentCompanyId}
                    helperText={errors.container?.company?.parentCompanyId?.message ?? ''}
                    onChange={(event) => handleParentCompanyChange(event)}
                  >
                    {Object.values(parentCompanies).map((parentCompany) => (
                      <MenuItem key={parentCompany.id} value={parentCompany.id}>
                        {shortenName(parentCompany.name)}
                      </MenuItem>
                    ))}
                  </ConardSelect>
                </Grid>

                <Grid
                  item
                  className={
                    new Set([FormViewMode.DRIVER, FormViewMode.KIOSK]).has(formMode) &&
                    container?.containerType === ContainerDtoContainerTypeEnum.ShippingContainer
                      ? classes.hidden
                      : classes.gridItem
                  }
                >
                  {formMode === FormViewMode.KIOSK ? (
                    <KioskLabel inactive={fieldDisabled('company')}>{t('form.containerForm.company')}</KioskLabel>
                  ) : (
                    <ConardLabel inactive={fieldDisabled('company')}>{t('form.containerForm.company')}</ConardLabel>
                  )}

                  <ConardSelect
                    roundedSquare
                    name="container.company.id"
                    control={control}
                    defaultValue=""
                    value={selectedCompany}
                    disabled={fieldDisabled('company')}
                    error={!!errors.container?.company?.id}
                    helperText={errors.container?.company?.id?.message ?? ''}
                    onChange={(event) => handleCompanyChange(event)}
                  >
                    {Object.values(companies).map((company) => (
                      <MenuItem key={company.id} value={company.id}>
                        {shortenName(company.name)}
                      </MenuItem>
                    ))}
                  </ConardSelect>
                </Grid>

                <Grid item className={classes.gridItem}>
                  <ContainerTypeField container={container} formMode={formMode} fieldDisabled={fieldDisabled} />
                </Grid>

                <Grid item className={classes.gridItem}>
                  {formMode === FormViewMode.KIOSK ? (
                    <KioskLabel inactive={fieldDisabled('tarraWeight')}>
                      {t('form.containerForm.tarraWeight')}
                    </KioskLabel>
                  ) : (
                    <ConardLabel inactive={fieldDisabled('tarraWeight')}>
                      {t('form.containerForm.tarraWeight')}
                    </ConardLabel>
                  )}

                  <ConardTextField
                    roundedSquare
                    name="container.tarraWeight"
                    control={control}
                    defaultValue=""
                    disabled={fieldDisabled('tarraWeight')}
                    error={!!errors.container?.tarraWeight}
                    helperText={errors.container?.tarraWeight?.message ?? ''}
                    type="number"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                    }}
                  />
                </Grid>

                {formMode === FormViewMode.EXTERN && (
                  <Grid item className={classes.gridItem}>
                    <ConardLabel inactive={fieldDisabled('maxGross')}>{t('form.containerForm.maxGross')}</ConardLabel>
                    <ConardTextField
                      roundedSquare
                      name="container.maxGross"
                      control={control}
                      defaultValue=""
                      disabled={fieldDisabled('maxGross')}
                      error={!!errors.container?.maxGross}
                      helperText={errors.container?.maxGross?.message ?? ''}
                      type="number"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                      }}
                    />
                  </Grid>
                )}
                {container?.containerType === ContainerDtoContainerTypeEnum.Semitrailer && (
                  <Grid item className={classes.gridItem}>
                    {formMode === FormViewMode.KIOSK ? (
                      <KioskLabel inactive={fieldDisabled('semitrailerLicencePlate')}>
                        {t('form.containerForm.semitrailerLicensePlate')}
                      </KioskLabel>
                    ) : (
                      <ConardLabel inactive={fieldDisabled('semitrailerLicencePlate')}>
                        {t('form.containerForm.semitrailerLicensePlate')}
                      </ConardLabel>
                    )}
                    <ConardTextField
                      roundedSquare
                      name="container.semitrailerLicencePlate"
                      control={control}
                      defaultValue=""
                      disabled={fieldDisabled('semitrailerLicencePlate')}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            {heavyTested && container?.containerType === ContainerDtoContainerTypeEnum.ShippingContainer && (
              <Grid item className={classes.gridItem}>
                <ContainerProperties container={container} />
              </Grid>
            )}
          </Grid>
        </AccordionDetails>
      </ConardAccordion>
    </div>
  )
}
