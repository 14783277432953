import React, { FC, useContext, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { createStyles, Grid, IconButton, Tooltip, Typography } from '@material-ui/core'
import {
  ContainerDtoContainerTypeEnum,
  TrainDto,
  TrainDtoTrainStateEnum,
  TrainWagonContentDto,
  TrainWagonDto,
  TransitionDto,
  TransitionSearchListDtoQualityTypeEnum,
  TransitionSimpleDto,
} from '../../api'
import { useTranslation } from 'react-i18next'
import CancelSharpIcon from '@material-ui/icons/CancelSharp'
import EditIcon from '@material-ui/icons/Edit'
import SearchIcon from '@material-ui/icons/Search'
import StraightenIcon from '@material-ui/icons/Straighten'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import LaunchIcon from '@material-ui/icons/Launch'
import { TrainMode } from '../../enums/TrainMode'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../../redux/store'
import {
  addUnseatedContent,
  clearWagonContent,
  setCurrentWagon,
  setCurrentWagonContent,
  setTrainSubmitDisabled,
  trainInitialState,
} from '../../redux/train/trainSlice'
import {
  setTransition,
  setTransitionFormDisabled,
  setTransitionPositionDisabled,
  setTransitionSaveDisabled,
  transitionInitialState,
} from '../../redux/transition/transitionSlice'
import { TransitionFormValidationScheme } from '../../validationSchemes/TransitionFormValidationScheme'
import { TransitionMode } from '../../enums/TransitionMode'
import { ScheduledContainerSelectionModal } from '../modals/ScheduledContainerSelectionModal'
import transitionService from '../../services/TransitionService'
import ConardTrainContainerIcon from '../train/ConardTrainContainerIcon'
import { convertToTons, renderContainerType } from '../../utils/utils'
import containerService from '../../services/ContainerService'
import { ConfigContext } from '../../context/ConfigContext'

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      height: '12vh',
      minHeight: '100px',
      textAlign: 'center',
      maxHeight: '120px',
    },
    containerHighlighted: {
      backgroundColor: theme.palette.info.main,
      border: `1px solid ${theme.palette.info.main}`,
    },
    containerUnHighlighted: {
      backgroundColor: theme.palette.primary.light,
      border: `1px solid ${theme.palette.info.main}`,
    },
    contentTypography: {
      wordWrap: 'break-word',
      [theme.breakpoints.down('lg')]: {
        fontSize: '15px',
      },
    },
    selectorWrapper: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    errorBorder: {
      border: `2px solid ${theme.palette.error.main}`,
    },
    iconButton: {
      padding: theme.spacing(1),
    },
    unloadedTransitionGrid: {
      backgroundColor: theme.palette.warning.light,
      border: `1px solid ${theme.palette.info.main}`,
    },
    containerInfoTypography: {
      color: theme.palette.primary.main,
      fontWeight: 550,
      fontSize: 14,
      margin: '0 1rem',
    },
  })
)

interface ConardTrainWagonContentProps {
  wagonContentModel: TrainWagonContentDto
  mode: TrainMode
  onCreateContainer?: () => void
  onCreateSemitrailer?: () => void
  onEditContainer?: () => void
  onEditSemitrailer?: () => void
  onLoadContainer?: (transitionId: number, qualityType: TransitionSearchListDtoQualityTypeEnum | undefined) => void
  onFill: () => void
  wagonModel?: TrainWagonDto
  isMiddleOverloaded: boolean
}

const ConardTrainWagonContentForm: FC<ConardTrainWagonContentProps> = (props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [open, setOpen] = useState<boolean>(false)
  const {
    wagonContentModel,
    mode,
    onCreateContainer,
    onCreateSemitrailer,
    onEditContainer,
    onEditSemitrailer,
    onLoadContainer,
    onFill,
    wagonModel,
    isMiddleOverloaded,
  } = props
  const classes = useStyles()

  const transition = useSelector<RootState, TransitionDto>((state) => state.transition.transition)
  const train = useSelector<RootState, TrainDto>((state) => state.train.train)
  const selectedUnseatedTransition = useSelector<RootState, TransitionSimpleDto | null>(
    (state) => state.train.selectedUnseatedTransition
  )
  const dispatch = useAppDispatch()
  const { damageLogRequiredByKiosk } = useContext(ConfigContext)

  const [validationError, setValidationError] = useState(false)
  const [containsAnyDamageLogEntries, setContainsAnyDamageLogEntries] = useState(false)

  const yupSchemaTransition = TransitionFormValidationScheme(TransitionMode.TRAIN, containsAnyDamageLogEntries)

  const onClearWagonContent = () => {
    if (mode === TrainMode.GATE_IN) {
      transitionService
        .delete(wagonContentModel.transition.id ?? -1)
        .then(() => dispatch(clearWagonContent(wagonContentModel)))
    }

    if (mode === TrainMode.GATE_OUT) {
      dispatch(addUnseatedContent(wagonContentModel.transition))
      dispatch(clearWagonContent(wagonContentModel))
    }
  }

  const onDetailSelect = () => {
    dispatch(setTransitionFormDisabled(true))
    dispatch(setTransitionPositionDisabled(true))
    dispatch(setTransitionSaveDisabled(true))
    dispatch(setTransition(transitionInitialState.transition))
    history.push('/search/transition/detail/' + wagonContentModel.transition.id)
  }

  const openDialog = () => {
    wagonModel && dispatch(setCurrentWagon(wagonModel))
    dispatch(setCurrentWagonContent(wagonContentModel))
    setOpen(true)
  }

  const closeDialog = () => {
    setOpen(false)
  }

  const onSave = (transitionId: number, qualityType: TransitionSearchListDtoQualityTypeEnum | undefined) => {
    if (onLoadContainer) {
      onLoadContainer(transitionId, qualityType)
    }
    setOpen(false)
  }

  const isTransitionEditable = (mode: TrainMode): boolean => {
    return !new Set([TrainMode.SEARCH]).has(mode)
  }

  useEffect(() => {
    if (mode === TrainMode.GATE_IN) {
      setValidationError(false)
      dispatch(setTrainSubmitDisabled(trainInitialState.trainSubmitDisabled))
      if (wagonContentModel.transition.container.iluCode.length > 0 && !transition) {
        yupSchemaTransition.isValid(wagonContentModel.transition).then((valid) => {
          setValidationError(!valid)
          if (!valid) {
            dispatch(setTrainSubmitDisabled(true))
          }
        })
      }
    }

    if (damageLogRequiredByKiosk && wagonContentModel.transition.container.id) {
      containerService.getAllDamages(wagonContentModel.transition.container.id).then((response) => {
        setContainsAnyDamageLogEntries([...response.data].length > 0)
      })
    }
  }, [wagonContentModel.transition]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={
        validationError ||
        (isMiddleOverloaded && (wagonContentModel.wagonContentOrder === 1 || wagonContentModel.wagonContentOrder === 2))
          ? `${classes.selectorWrapper} ${classes.errorBorder}`
          : `${classes.selectorWrapper}`
      }
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={`${classes.container} ${
          !wagonContentModel.transition.id && selectedUnseatedTransition
            ? classes.containerHighlighted
            : mode !== TrainMode.GATE_OUT ||
              wagonContentModel.transition.container.iluCode.length === 0 ||
              wagonContentModel.transition.loadedOnTrain
            ? classes.containerUnHighlighted
            : classes.unloadedTransitionGrid
        }`}
        onClick={onFill}
      >
        <Grid item>
          {wagonContentModel.transition.container.iluCode.length === 0 && (
            <Grid container direction="column" justifyContent="center" alignItems="center">
              <Grid item>
                <Typography variant="h6" className={classes.contentTypography} color="primary">
                  {t('pages.train.labels.wagonContentEmpty')}
                </Typography>
              </Grid>
              <Grid item>
                {mode === TrainMode.GATE_IN && (
                  <>
                    <Tooltip title={t<string>('pages.gateIn.tooltip.newContainer')}>
                      <IconButton className={classes.iconButton} onClick={onCreateContainer}>
                        <StraightenIcon color="primary" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t<string>('pages.gateIn.tooltip.newSemitrailer')}>
                      <IconButton className={classes.iconButton} onClick={onCreateSemitrailer}>
                        <LocalShippingIcon color="primary" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t<string>('pages.gateIn.tooltip.addPlannedContainer')}>
                      <IconButton className={classes.iconButton} onClick={() => openDialog()}>
                        <LaunchIcon color="primary" />
                      </IconButton>
                    </Tooltip>
                    <ScheduledContainerSelectionModal
                      open={open}
                      onClose={closeDialog}
                      onSave={onSave}
                    ></ScheduledContainerSelectionModal>
                  </>
                )}
              </Grid>
            </Grid>
          )}

          {wagonContentModel.transition.container.iluCode.length > 0 && (
            <Grid container direction="column" justifyContent="center" alignItems="center">
              <Grid item>
                <Typography variant="h6" className={classes.contentTypography} color="primary">
                  {wagonContentModel.transition.container.iluCode}
                </Typography>
                <Grid container direction="row" alignItems="center">
                  <Typography className={classes.containerInfoTypography}>
                    {renderContainerType({
                      containerType: wagonContentModel.transition.container.containerType,
                      shippingContainerType: wagonContentModel.transition.container.shippingContainerType,
                      semitrailerType: wagonContentModel.transition.container.semitrailerType,
                    })}
                  </Typography>
                  <ConardTrainContainerIcon
                    containerType={wagonContentModel.transition.container.containerType}
                    repleted={wagonContentModel.transition.repleted ?? false}
                  />
                  <Typography className={classes.containerInfoTypography}>
                    {convertToTons(wagonContentModel.transition.grossWeight ?? 0) + ' ' + t('any.units.ton')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                {mode !== TrainMode.SEARCH &&
                  !new Set([TrainDtoTrainStateEnum.OutgoingLoaded, TrainDtoTrainStateEnum.OutgoingDispatched]).has(
                    train.trainState ?? TrainDtoTrainStateEnum.IncomingAccepted
                  ) &&
                  (mode !== TrainMode.GATE_IN || TrainDtoTrainStateEnum.IncomingFinished !== train.trainState) && (
                    <IconButton className={classes.iconButton} onClick={onClearWagonContent}>
                      <CancelSharpIcon color="primary" />
                    </IconButton>
                  )}
                {isTransitionEditable(mode) &&
                  transition?.container?.containerType === ContainerDtoContainerTypeEnum.ShippingContainer && (
                    <IconButton className={classes.iconButton} onClick={onEditContainer}>
                      <EditIcon color="primary" />
                    </IconButton>
                  )}
                {isTransitionEditable(mode) &&
                  transition?.container?.containerType === ContainerDtoContainerTypeEnum.Semitrailer && (
                    <IconButton className={classes.iconButton} onClick={onEditSemitrailer}>
                      <EditIcon color="primary" />
                    </IconButton>
                  )}

                {mode === TrainMode.SEARCH && (
                  <IconButton className={classes.iconButton} onClick={onDetailSelect}>
                    <SearchIcon color="primary" />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  )
}

export default ConardTrainWagonContentForm
