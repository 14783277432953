import { VgmApi } from '../api'
import myAxios from '../config/axios/axios'
import { AxiosPromise } from 'axios'

let api: VgmApi

export const setVgmApiConfiguration = () => {
  api = new VgmApi(undefined, process.env.REACT_APP_BE_REST_URL, myAxios)
}

const vgmService = {
  generateVgm: (transitionId: number): AxiosPromise => {
    return api.vgmDownloadProcessedFile(transitionId, { responseType: 'blob' })
  },
}

export default vgmService
