import React from 'react'
import { useFormContext } from 'react-hook-form'
import { makeStyles } from '@material-ui/core'
import { ConardLabel } from '../../../components/transition/DriverFields'
import { useTranslation } from 'react-i18next'
import ConardTextField from '../../../components/ConardTextField'

const useStyles = makeStyles({
  divWrapper: {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 2px 4px 0 lightGray',
  },
})

export const NoteField = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { control, errors } = useFormContext()

  return (
    <div className={classes.divWrapper}>
      <ConardLabel>{t('form.transitionForm.note')}</ConardLabel>
      <ConardTextField
        roundedSquare
        name="note"
        control={control}
        defaultValue=""
        error={!!errors.container?.note}
        helperText={errors.container?.note?.message ?? ''}
      />
    </div>
  )
}
