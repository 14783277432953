import { AxiosPromise } from 'axios'
import {
  ContainerStateDtoStatusEnum,
  OperatorTransitionSearchDto,
  PageOperatorTransitionDto,
  PageTransitionDto,
  PageTransitionSearchListDto,
  TransitionApi,
  TransitionDto,
  TransitionSearchDto,
  TransitionSearchListDto,
  TransitionSearchListSearchDto,
  TransitionStoreDto,
} from '../api'
import myAxios from '../config/axios/axios'

let api: TransitionApi

export const setTransitionApiConfiguration = () => {
  api = new TransitionApi(undefined, process.env.REACT_APP_BE_REST_URL, myAxios)
}

const trimSearchDto = (searchDto?: TransitionSearchDto | TransitionSearchListSearchDto) => {
  return {
    ...searchDto,
    container: {
      ...searchDto?.container,
      iluCode: searchDto?.container?.iluCode?.trim(),
      semitrailerLicencePlate: searchDto?.container?.semitrailerLicencePlate?.trim(),
    },
    trainName: searchDto?.trainName?.trim(),
    mrn: searchDto?.mrn?.trim(),
  }
}

export const transitionService = {
  searchForList: (
    page?: number,
    size?: number,
    sort?: string[],
    searchDto?: TransitionSearchListSearchDto
  ): AxiosPromise<PageTransitionSearchListDto> => {
    return api.transitionSearchForList(page, size, sort, trimSearchDto(searchDto) as TransitionSearchListSearchDto)
  },

  search: (
    page?: number,
    size?: number,
    sort?: string[],
    searchDto?: TransitionSearchDto
  ): AxiosPromise<PageTransitionDto> => {
    return api.transitionSearch(page, size, sort, trimSearchDto(searchDto) as TransitionSearchDto)
  },

  searchForListPlanned: (
    page?: number,
    size?: number,
    sort?: string[],
    searchDto?: TransitionSearchListSearchDto
  ): AxiosPromise<PageTransitionSearchListDto> => {
    return api.transitionSearchForListPlanned(
      page,
      size,
      sort,
      trimSearchDto(searchDto) as TransitionSearchListSearchDto
    )
  },

  findById: (id: number): AxiosPromise<TransitionDto> => {
    return api.transitionFindById(id)
  },

  findByIdOnSite: (id: number): AxiosPromise<TransitionSearchListDto> => {
    return api.transitionFindByIdOnSite(id)
  },

  update: (id: number, transitionDto: TransitionDto): AxiosPromise<TransitionDto> => {
    return api.transitionUpdate(id, transitionDto)
  },

  downloadExport: (
    exportType: 'STANDARD' | 'ADR_RID',
    language: 'CZECH' | 'ENGLISH',
    transitionSearchDto: TransitionSearchDto | null
  ): AxiosPromise => {
    return api.transitionDownloadExport(exportType, language, transitionSearchDto || {}, { responseType: 'blob' })
  },

  delete: (id: number): AxiosPromise<void> => {
    return api.transitionDelete(id)
  },

  changeContainerState: (id: number, containerState: ContainerStateDtoStatusEnum): AxiosPromise<TransitionDto> => {
    return api.transitionChangeContainerState(id, { status: containerState })
  },

  findLastByIlu: (iluCode: string): AxiosPromise<TransitionDto> => {
    return api.transitionFindLastByIlu(iluCode)
  },

  operatorSearch: (
    page?: number,
    size?: number,
    sort?: string[],
    searchDto?: OperatorTransitionSearchDto
  ): AxiosPromise<PageOperatorTransitionDto> => {
    return api.transitionOperatorSearch(page, size, sort, {
      ...searchDto,
      iluCode: searchDto?.iluCode?.trim(),
      truckLicencePlate: searchDto?.truckLicencePlate?.trim(),
      trainName: searchDto?.trainName?.trim(),
      medlogReference: searchDto?.medlogReference?.trim(),
    })
  },

  changeContainerPosition: (id: number, transitionStoreDto: TransitionStoreDto): AxiosPromise<TransitionDto> => {
    return api.transitionChangeContainerPosition(id, transitionStoreDto)
  },

  customsSearch: (
    page?: number,
    size?: number,
    sort?: string[],
    searchDto?: TransitionSearchDto
  ): AxiosPromise<PageTransitionDto> => {
    return api.transitionCustomsSearch(page, size, sort, trimSearchDto(searchDto) as TransitionSearchDto)
  },

  carrierSearch: (
    page?: number,
    size?: number,
    sort?: string[],
    searchDto?: TransitionSearchListSearchDto
  ): AxiosPromise<PageTransitionSearchListDto> => {
    return api.transitionCarrierSearch(page, size, sort, trimSearchDto(searchDto) as TransitionSearchListSearchDto)
  },

  customsSearchHistory: (
    page?: number,
    size?: number,
    sort?: string[],
    searchDto?: TransitionSearchDto
  ): AxiosPromise<PageTransitionDto> => {
    return api.transitionCustomsSearchHistory(page, size, sort, trimSearchDto(searchDto) as TransitionSearchDto)
  },

  changeCustomsInfo: (transitions: Array<TransitionDto>, stateChanged: boolean): AxiosPromise<Array<TransitionDto>> => {
    return api.transitionChangeCustomsInfo(stateChanged, transitions)
  },

  isTransitionWithoutGo: (id: number): AxiosPromise<boolean> => {
    return api.transitionIsTransitionWithoutGo(id)
  },

  createCustomsInfo: (id: number): AxiosPromise<TransitionDto> => {
    return api.transitionCreateCustomsInfo(id)
  },

  deleteCustomsInfos: (ids: Array<number>): AxiosPromise<void> => {
    return api.transitionDeleteCustomsInfo(ids)
  },

  isValidIluCode: (iluCode: string): AxiosPromise<boolean> => {
    return api.transitionIsValidIluCode(iluCode)
  },

  updateByDriver: (
    transitionId: number,
    driverArrivalId: number,
    transition: TransitionDto
  ): AxiosPromise<TransitionDto> => {
    return api.transitionUpdateByDriver(transitionId, driverArrivalId, transition)
  },

  changeLoadedOnTrain: (transitionId: number, loadedOnTrain: boolean): AxiosPromise<TransitionDto> => {
    return api.transitionChangeLoadedOnTrain(transitionId, loadedOnTrain)
  },

  downloadTransitionPdfExport: (transitionId: number): AxiosPromise => {
    return api.transitionDownloadPdf(transitionId, { responseType: 'blob' })
  },

  updateVgm: (transitionId: number, vgmValue: number): AxiosPromise => {
    return api.transitionUpdateVgm(transitionId, { vgm: vgmValue })
  },

  createExtern: (transition: TransitionDto): AxiosPromise<TransitionDto> => {
    return api.transitionCreateExtern(transition)
  },
}

export default transitionService
