import React, { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next/'
import { PageTrainViewDto, TrainViewDto, UserDtoRoleEnum } from '../../api'
import {
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core'
import ConardButton from '../ConardButton'
import AddIcon from '@material-ui/icons/Add'
import { useAppDispatch } from '../../redux/store'
import { useHistory } from 'react-router-dom'
import { setInitialTrain, setSelectedUnseatedTransition } from '../../redux/train/trainSlice'
import { Order } from '../../enums/Order'
import { format } from 'date-fns'
import ConardProgressBar from '../ConardProgressBar'
import trainGateInService from '../../services/TrainGateInService'
import { useConardAuth } from '../../hooks/useConardAuth'
import { formatMinutesToHours } from '../../utils/utils'

const useStyles = makeStyles({
  gridContainer: {
    marginTop: '8vh',
  },
  addButton: {
    borderRadius: 12,
    height: '50px',
    width: '300px',
    float: 'right',
  },
  detailButton: {
    width: '7vw',
  },
})

interface HeadCell {
  id: keyof TrainViewDto
  label: string
}

export const GateInTrainView = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const history = useHistory()
  const auth = useConardAuth()

  const userRole = auth.getRole()

  const headCells: HeadCell[] = [
    { id: 'name', label: t('pages.train.labels.trainName') },
    { id: 'trainState', label: t('pages.train.labels.trainState') },
    { id: 'companies', label: t('pages.train.labels.companies') },
    { id: 'plannedDateTime', label: t('pages.train.labels.plannedArrivalDate') },
    { id: 'departureDelayMinutes', label: t('pages.train.labels.departureDelayMinutes') },
    {
      id: 'departureDateTime',
      label: t('pages.train.labels.trainArrivalTime'),
    },
    {
      id: 'unloadingDateTime',
      label: t('pages.train.labels.unloadingDateTime'),
    },
  ]

  const [orderBy, setOrderBy] = useState<keyof TrainViewDto>('departureDateTime')
  const [order, setOrder] = useState<Order>(Order.Desc)
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(50)
  const [pageTrainsView, setPageTrainsView] = useState<PageTrainViewDto | null>()

  const handleSort = (property: keyof TrainViewDto) => () => {
    const isAsc = orderBy === property && order === Order.Asc
    setOrder(isAsc ? Order.Desc : Order.Asc)
    setOrderBy(property)
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
    setPage(page)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPageSize(Number.parseInt(event.target.value))
    setPage(0)
  }

  const add = () => {
    dispatch(setInitialTrain())
    dispatch(setSelectedUnseatedTransition(null))

    history.push('/gate-in/train/create')
  }

  const search = () => {
    let currentSort: Array<string> | undefined = undefined
    if (orderBy !== undefined) {
      const currentOrder: string = order !== undefined ? order : 'asc'
      currentSort = [orderBy + ',' + currentOrder]
    }

    trainGateInService.getGateInTrainView(page, pageSize, currentSort).then((response) => {
      setPageTrainsView(response.data)
    })
  }

  useEffect(() => {
    search()
  }, [page, pageSize, orderBy, order]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Typography variant="h4" color="primary">
        {t('pages.gateIn.headers.trainPreparingList')}
      </Typography>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={3}
        className={classes.gridContainer}
      >
        {userRole !== UserDtoRoleEnum.Carrier && (
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <ConardButton className={classes.addButton} conardVariant="light" startIcon={<AddIcon />} onClick={add}>
              {t('any.buttons.add')}
            </ConardButton>
          </Grid>
        )}

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Paper variant="outlined">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => {
                      const sortable = headCell.id !== 'companies' && headCell.id !== 'departureDelayMinutes'
                      return (
                        <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
                          {sortable ? (
                            <TableSortLabel
                              active={orderBy === headCell.id}
                              direction={orderBy === headCell.id ? order : 'asc'}
                              onClick={handleSort(headCell.id)}
                            >
                              {headCell.label}
                            </TableSortLabel>
                          ) : (
                            headCell.label
                          )}
                        </TableCell>
                      )
                    })}
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pageTrainsView?.content?.map((train) => (
                    <TableRow key={train.id}>
                      <TableCell component="th" scope="row">
                        {train.name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {t(`enum.trainState.${train.trainState}`)}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {train.companies?.map((company) => company).join(', ')}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {train.plannedDateTime ? format(new Date(train.plannedDateTime), 'dd.MM.yyyy HH:mm') : ''}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {train.departureDelayMinutes !== undefined
                          ? formatMinutesToHours(train.departureDelayMinutes)
                          : ''}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {train.departureDateTime ? format(new Date(train.departureDateTime), 'dd.MM.yyyy HH:mm') : ''}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {train.unloadingDateTime ? format(new Date(train.unloadingDateTime), 'dd.MM.yyyy HH:mm') : ''}
                      </TableCell>
                      {userRole !== UserDtoRoleEnum.Carrier && (
                        <TableCell className={classes.detailButton}>
                          <ConardButton
                            conardVariant="light"
                            onClick={() => history.push(`/gate-in/train/${train.id}`)}
                          >
                            {t('any.buttons.detail')}
                          </ConardButton>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}

                  {pageTrainsView?.content && pageTrainsView?.totalElements === 0 && (
                    <TableRow key="noData">
                      <TableCell component="th" scope="row" colSpan={7}>
                        {t('pages.common.table.noData')}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <ConardProgressBar showBar={!pageTrainsView} />
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50, 150]}
              component="div"
              count={pageTrainsView?.totalElements ?? 0}
              rowsPerPage={pageSize}
              labelRowsPerPage={t('pages.common.pagination.rowsPerPage')}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}
