import React, { useEffect, useState } from 'react'
import { TransitionForm } from '../drivers/transition-form/TransitionForm'
import {
  ContainerDtoContainerTypeEnum,
  TransitionDto,
  TransitionDtoContainerStateEnum,
  TransitionDtoDirectionEnum,
  TransitionDtoReceiptMethodEnum,
} from '../../api'
import { FormViewMode } from '../../enums/FormViewMode'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import transitionService from '../../services/TransitionService'
import uploadedFileService from '../../services/UploadedFileService'

const initialValues: TransitionDto = {
  container: {
    iluCode: '',
    teu: 1,
    containerType: ContainerDtoContainerTypeEnum.ShippingContainer,
  },
  direction: TransitionDtoDirectionEnum.Extern,
  repleted: true,
  adrRid: false,
  transitionDateTime: new Date().toISOString(),
  extras: [],
  weighted: true,
}

export const ExternTransitionForm = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const queryParams = new URLSearchParams(location.search)
  const transitionId = queryParams.get('id')

  const [transition, setTransition] = useState(initialValues)
  const [loaded, setLoaded] = useState(false)
  const [signetPhoto, setSignetPhoto] = useState('')
  const [signetPhotoChanged, setSignedPhotoChanged] = useState(false)
  const [loadingSignetPhoto, setLoadingSignetPhoto] = useState(true)

  useEffect(() => {
    if (transitionId && Number(transitionId)) {
      transitionService
        .findById(Number(transitionId))
        .then((response) => {
          setTransition(response.data)
        })
        .finally(() => setLoaded(true))
      uploadedFileService
        .getSignetPhoto(Number(transitionId))
        .then((response) => {
          setSignetPhoto(response.data.content ?? '')
        })
        .finally(() => setLoadingSignetPhoto(false))
      return
    }
    setLoaded(true)
    setLoadingSignetPhoto(false)
  }, [transitionId])

  const handleSubmit = (values: TransitionDto) => {
    if (transition.id) {
      transitionService
        .update(transition.id, {
          ...transition,
          ...values,
          container: {
            ...transition.container,
            ...values.container,
          },
        })
        .then((response) => {
          uploadSignetPhoto(response.data.id ?? -1)
          history.push(`/success/transition/${response.data.id}/extern`)
        })
    } else {
      //TODO create new EP for this?
      transitionService
        .createExtern({
          ...values,
          container: { ...values.container, containerType: ContainerDtoContainerTypeEnum.ShippingContainer },
          direction: TransitionDtoDirectionEnum.Extern,
          manipulation: 0,
          receiptMethod: TransitionDtoReceiptMethodEnum.Road,
          containerState: TransitionDtoContainerStateEnum.ProcessingExtern,
        })
        .then((response) => {
          uploadSignetPhoto(response.data.id ?? -1)
          history.push(`/success/transition/${response.data.id}/extern`)
        })
    }
  }

  const handleNewPhoto = (newPhoto: string) => {
    setSignetPhoto(newPhoto)
    setSignedPhotoChanged(true)
  }

  const uploadSignetPhoto = (transitionId: number) => {
    if (signetPhotoChanged) {
      if (signetPhoto === '') {
        uploadedFileService.deleteSignetPhoto(transitionId)
      } else {
        uploadedFileService.assignSignetPhoto(transitionId, { content: signetPhoto })
      }
    }
  }

  if (!loaded) {
    //TODO create new component with alert
    return <></>
  }

  return (
    <TransitionForm
      initialValues={transition}
      driverId={transition.driverShort?.id}
      onSubmit={handleSubmit}
      formMode={FormViewMode.EXTERN}
      signetPhoto={signetPhoto}
      loadingSignetPhoto={loadingSignetPhoto}
      handleNewPhoto={handleNewPhoto}
      submitButtonText={transitionId && Number(transitionId) ? t('any.buttons.save') : t('any.buttons.create')}
      title={
        transitionId && Number(transitionId) ? t('pages.extern.headers.extern') : t('pages.extern.headers.newExtern')
      }
    />
  )
}
