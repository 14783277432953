import React, { FC, useContext, useState } from 'react'
import { TrainDto, TrainDtoTrainTypeEnum, UserDtoRoleEnum } from '../../api'
import { TrainMode } from '../../enums/TrainMode'
import { RootState, useAppDispatch } from '../../redux/store'
import { setTrain } from '../../redux/train/trainSlice'
import * as yup from 'yup'
import { useSelector } from 'react-redux'
import { createStyles, Grid, makeStyles, Typography } from '@material-ui/core'
import ConardTextField from '../../components/ConardTextField'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import ConardDateTimePicker from '../../components/ConardDateTimePicker'
import ConardSwitch from '../../components/ConardSwitch'
import { useTranslation } from 'react-i18next'
import { useConardAuth } from '../../hooks/useConardAuth'
import { ConfigContext } from '../../context/ConfigContext'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import ConardButton from '../../components/ConardButton'
import { useHistory } from 'react-router-dom'
import { getDateWithZeroSeconds } from '../../utils/utils'

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      marginTop: '10vh',
      width: '50vw',
      minWidth: '300px',
      margin: 'auto',
    },
    controls: {
      [theme.breakpoints.up('sm')]: {
        left: '10vw',
      },
      [theme.breakpoints.down('xs')]: {
        left: '0px',
      },
      position: 'fixed',
      bottom: 0,
      width: '100%',
      backgroundColor: theme.palette.secondary.main,
      zIndex: 100,
    },
    submitButton: {
      width: '15vw',
      minWidth: '200px',
      textAlign: 'center',
      marginTop: '2vh',
      marginBottom: '2vh',
      marginLeft: '5vw',
    },
  })
)

interface ConardCreateTrainFormProps {
  mode: TrainMode
  onSubmit: (train: TrainDto, onSubmit: boolean) => void
}

const ConardCreateTrainForm: FC<ConardCreateTrainFormProps> = ({ mode, onSubmit }) => {
  const auth = useConardAuth()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const classes = useStyles()
  const { customsClearance } = useContext(ConfigContext)
  const history = useHistory()

  const train = useSelector<RootState, TrainDto>((state) => state.train.train)
  const [onTerminal, setOnTerminal] = useState<boolean>(false)

  const yupSchema = yup.object().shape({
    name: yup
      .string()
      .required(t('pages.train.validations.trainName.required'))
      .min(3, t('pages.train.validations.trainName.min', { min: 3 })),
  })

  const { control, errors, handleSubmit } = useForm<TrainDto>({
    resolver: yupResolver(yupSchema),
    defaultValues: {
      ...train,
      name: train.name,
    },
  })

  const onChangeOnTerminal = (event: boolean) => {
    setOnTerminal(event)
  }

  const onChangeTrainType = (event: boolean) => {
    control.setValue('trainType', event ? TrainDtoTrainTypeEnum.Partial : TrainDtoTrainTypeEnum.Complete)
    dispatch(
      setTrain({
        ...train,
        trainType: event ? TrainDtoTrainTypeEnum.Partial : TrainDtoTrainTypeEnum.Complete,
      })
    )
  }

  const onChangeCustomsTrain = (event: boolean) => {
    control.setValue('customsClearance', event)
    dispatch(
      setTrain({
        ...train,
        customsClearance: event,
      })
    )
  }

  const getDepartureTimeLabel = () => {
    if (mode === TrainMode.GATE_IN) {
      if (onTerminal) {
        return t('pages.train.labels.trainArrivalTime')
      } else {
        return t('pages.train.labels.trainPlannedArrivalTime')
      }
    }

    return ''
  }

  const onChangeDepartureDateTime = (event: MaterialUiPickersDate) => {
    if (event !== null) {
      dispatch(
        setTrain({
          ...train,
          departureDateTime: event.toISOString(),
          plannedDateTime: event.toISOString(),
        })
      )
    }
  }

  const submitForm = (values: TrainDto) => {
    onSubmit(
      {
        ...values,
        trainState: train.trainState,
        trainType: train.trainType,
        plannedDateTime: onTerminal ? values.departureDateTime : undefined,
      },
      onTerminal
    )
  }

  return (
    <>
      <Grid item xs={12}>
        {mode == TrainMode.GATE_IN && (
          <Typography variant="h4" color="primary">
            {t('pages.train.labels.formGateInNew')}
          </Typography>
        )}
        {mode == TrainMode.GATE_OUT && (
          <Typography variant="h4" color="primary">
            {t('pages.train.labels.formGateOutNew')}
          </Typography>
        )}
      </Grid>
      <form onSubmit={handleSubmit(submitForm)}>
        <Grid
          container
          className={classes.container}
          direction="row"
          alignItems="flex-start"
          justifyContent="center"
          spacing={4}
        >
          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={3}>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="name"
                name="name"
                label={t('pages.train.labels.trainName')}
                fullWidth
                control={control}
                error={!!errors.name}
                helperText={errors.name ? errors.name.message : ''}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardSwitch
                id="trainType"
                name="trainType"
                checked={train?.trainType === TrainDtoTrainTypeEnum.Partial}
                onChange={(event) => onChangeTrainType(event)}
                control={control}
                checkedLabel={t('pages.gateOut.form.trainType.checked')}
                uncheckedLabel={t('pages.gateOut.form.trainType.unchecked')}
              />
            </Grid>
            {customsClearance && mode === TrainMode.GATE_IN && (
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <ConardSwitch
                  id="customsClearance"
                  name="customsClearance"
                  checked={train?.customsClearance ?? false}
                  onChange={(event) => onChangeCustomsTrain(event)}
                  control={control}
                  checkedLabel={t('pages.gateOut.form.customsTrain.checked')}
                  uncheckedLabel={t('pages.gateOut.form.customsTrain.unchecked')}
                />
              </Grid>
            )}
            {mode === TrainMode.GATE_IN && (
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <ConardSwitch
                  id="onTerminal"
                  name="onTerminal"
                  checked={onTerminal}
                  defaultChecked={false}
                  onChange={(event) => onChangeOnTerminal(event)}
                  control={control}
                  checkedLabel={t('pages.gateOut.form.trainState.incomingAccepted')}
                  uncheckedLabel={t('pages.gateOut.form.trainState.incomingPlanned')}
                  disabled={auth.getRole() === UserDtoRoleEnum.Operator || mode !== TrainMode.GATE_IN}
                />
              </Grid>
            )}
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardDateTimePicker
                name="departureDateTime"
                defaultValue={!!train.departureDateTime ? getDateWithZeroSeconds(train.departureDateTime!) : undefined}
                control={control}
                label={getDepartureTimeLabel()}
                onChange={(event) => onChangeDepartureDateTime(event)}
                clearable
              />
            </Grid>
          </Grid>
        </Grid>
        <div className={classes.controls}>
          <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
              <div className={classes.submitButton}>
                <ConardButton conardVariant="dark" type="submit">
                  {t('any.buttons.save')}
                </ConardButton>
              </div>
            </Grid>
            <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
              <div className={classes.submitButton}>
                <ConardButton conardVariant="dark" onClick={() => history.goBack()}>
                  {t('any.buttons.back')}
                </ConardButton>
              </div>
            </Grid>
          </Grid>
        </div>
      </form>
    </>
  )
}

export default ConardCreateTrainForm
