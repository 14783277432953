import { AxiosPromise } from 'axios'
import { PageProcessedFileDto, ProcessedFileApi, ProcessedFileDto, ProcessedFileSearchDto } from '../api'
import myAxios from '../config/axios/axios'

let api: ProcessedFileApi

export const setProcessedFileApiConfiguration = () => {
  api = new ProcessedFileApi(undefined, process.env.REACT_APP_BE_REST_URL, myAxios)
}

const processedFileService = {
  search: (
    page?: number,
    size?: number,
    sort?: string[],
    searchDto?: ProcessedFileSearchDto
  ): AxiosPromise<PageProcessedFileDto> => {
    return api.processedFileSearch(page, size, sort, searchDto)
  },

  download: (id: string): AxiosPromise => {
    return api.processedFileDownloadProcessedFile(id, { responseType: 'blob' })
  },

  handleDownload: (processedFile: ProcessedFileDto | undefined) => {
    if (!!processedFile) {
      api
        .processedFileDownloadProcessedFile(processedFile.contentId!, { responseType: 'blob' })
        .then((response) => {
          const url = window.URL.createObjectURL(response.data)
          const a = document.createElement('a')
          a.href = url
          a.download = processedFile.fileName ?? ''
          a.click()
        })
        .catch()
    }
  },

  findLatestCodeco: (transitionId: number): AxiosPromise<ProcessedFileDto> => {
    return api.processedFileSearchLatestCodeco(transitionId)
  },

  findLatestVeronaMessage: (trainId: number): AxiosPromise<ProcessedFileDto> => {
    return api.processedFileSearchLatestVeronaMessage12(trainId)
  },

  findLatestCdCargoMessage: (trainId: number): AxiosPromise<ProcessedFileDto> => {
    return api.processedFileSearchLatestCdCargo(trainId)
  },

  findLatestVgmProtocol: (transitionId: number): AxiosPromise<ProcessedFileDto> => {
    return api.processedFileSearchLatestVgmProtocol(transitionId)
  },
}

export default processedFileService
