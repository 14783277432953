import React, { FC, useEffect, useState } from 'react'
import processedFileService from '../../services/ProcessedFileService'
import { Grid, Typography } from '@mui/material'
import ConardButton from '../ConardButton'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { alpha, CircularProgress, createStyles } from '@material-ui/core'
import vgmService from '../../services/VgmService'
import { ProcessedFileDto } from '../../api'
import { format } from 'date-fns'

const useStyles = makeStyles((theme) =>
  createStyles({
    panelContainer: {
      marginTop: '4vh',
      marginBottom: '4vh',
      backgroundColor: alpha(theme.palette.secondary.dark, 0.3),
      borderRadius: '8px',
      textAlign: 'center',
      minWidth: '290px',
      padding: '20px',
    },
    button: {
      marginTop: '20px',
    },
  })
)

interface ConardTransitionFormVgmBoxProps {
  transitionId: number
  hasVgm: boolean
}

export const ConardTransitionFormVgmBox: FC<ConardTransitionFormVgmBoxProps> = ({ transitionId, hasVgm }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [processedFile, setProcessedFile] = useState<ProcessedFileDto | undefined>(undefined)
  const [loaded, setLoaded] = useState(false)

  const loadProcessedFiles = () => {
    processedFileService
      .findLatestVgmProtocol(transitionId)
      .then((response) => {
        setProcessedFile(response.data)
      })
      .finally(() => setLoaded(true))
  }

  useEffect(() => {
    loadProcessedFiles()
  }, [transitionId]) // eslint-disable-line react-hooks/exhaustive-deps

  const generateVgm = () => {
    vgmService.generateVgm(transitionId).then((response) => {
      if (response.data) {
        const url = window.URL.createObjectURL(response.data)
        const a = document.createElement('a')
        a.href = url
        a.download = `VGM_${transitionId}.pdf`
        a.click()

        window.URL.revokeObjectURL(url)
        setLoaded(false)
        loadProcessedFiles()
      }
    })
  }

  const downloadVgm = () => {
    if (processedFile?.contentId)
      processedFileService.download(processedFile.contentId).then((response) => {
        const url = window.URL.createObjectURL(response.data)
        const a = document.createElement('a')
        a.href = url
        a.download = processedFile.fileName ?? ''
        a.click()

        window.URL.revokeObjectURL(url)
      })
  }

  const showBoxContent = () => {
    if (!loaded) {
      return <CircularProgress color="primary" size={60} thickness={6} />
    }
    if (!processedFile) {
      return (
        <>
          <Typography>
            {hasVgm ? t('pages.gateIn.headers.vgmProtocolNotFound') : t('pages.gateIn.headers.vgmProtocolNotWeighed')}
          </Typography>
          {hasVgm && (
            <ConardButton conardVariant="dark" onClick={generateVgm} className={classes.button}>
              {t('any.buttons.generate')}
            </ConardButton>
          )}
        </>
      )
    }
    return (
      <>
        <span>{`${t('pages.gateIn.headers.vgmProtocolCreatedAt')}: ${format(
          new Date(processedFile.processed ?? ''),
          'd.M.yyyy HH:mm'
        )}`}</span>
        <ConardButton conardVariant="dark" onClick={downloadVgm} className={classes.button}>
          {t('any.buttons.download')}
        </ConardButton>
        <ConardButton conardVariant="dark" onClick={generateVgm} className={classes.button}>
          {t('any.buttons.generate')}
        </ConardButton>
      </>
    )
  }

  return (
    <Grid container direction="column" justifyContent="center" alignItems="center" className={classes.panelContainer}>
      <Grid item>
        <Typography variant="h5">{t('pages.gateIn.headers.vgmProtocol')}</Typography>
      </Grid>
      <Grid item>{showBoxContent()}</Grid>
    </Grid>
  )
}
