import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { ContainerDtoContainerTypeEnum, TransitionDto } from '../../api'
import { TransitionForm } from './transition-form/TransitionForm'
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from '@material-ui/core'
import driverArrivalService from '../../services/DriverArrivalService'
import gateInService from '../../services/GateInService'
import { FormViewMode } from '../../enums/FormViewMode'
import transitionService from '../../services/TransitionService'
import uploadedFileService from '../../services/UploadedFileService'
import { useTranslation } from 'react-i18next'
import ConardButton from '../../components/ConardButton'
import { AxiosError } from 'axios'

const useStyles = makeStyles({
  button: {
    borderRadius: 12,
  },
})

export const DriverGateInPage = () => {
  const location = useLocation()
  const history = useHistory()
  const classes = useStyles()
  const { t } = useTranslation()

  const [transition, setTransition] = useState<TransitionDto | undefined>(undefined)
  const [driverArrivalId, setDriverArrivalId] = useState(-1)
  const [dateTime, setDateTime] = useState<string>('')
  const [driverId, setDriverId] = useState(-1)
  const [signetPhoto, setSignetPhoto] = useState('')
  const [signetPhotoChanged, setSignedPhotoChanged] = useState(false)
  const [loadingSignetPhoto, setLoadingSignetPhoto] = useState(true)
  const [loaded, setLoaded] = useState(false)
  const [showNoReferenceDialog, setShowNoReferenceDialog] = useState(false)

  const { id: idDriverArrival } = useParams<{
    id: string
    containerId: string
  }>()

  const loadTransition = useCallback(() => {
    const searchParams = new URLSearchParams(location.search)
    const ilucode = searchParams.get('ilucode')
    const containerType = searchParams.get('containerType')
    const containerId = Number(searchParams.get('containerId'))

    if (containerId) {
      const fetchData = async () => {
        try {
          const response = await gateInService.getPlannedTransitionForDriver(
            Number(idDriverArrival),
            Number(containerId)
          )

          if (response.data) {
            setTransition({
              ...response.data,
              transitionDateTime: dateTime,
            })
          }
        } catch (err: unknown) {
          if (err instanceof Error && 'response' in err) {
            const axiosError = err as AxiosError
            if (axiosError.response?.status === 400) {
              history.goBack()
            } else {
              console.error(axiosError)
            }
          }
        }
      }
      fetchData()
    } else {
      setTransition({
        container: {
          iluCode: ilucode ?? '',
          teu: 1,
          containerType:
            containerType === 'SEMITRAILER'
              ? ContainerDtoContainerTypeEnum.Semitrailer
              : ContainerDtoContainerTypeEnum.ShippingContainer,
        },
        repleted: false,
        adrRid: false,
        transitionDateTime: dateTime,
      })
    }
  }, [dateTime, history, idDriverArrival, location.search])

  useEffect(() => {
    driverArrivalService.getById(Number(idDriverArrival)).then((response) => {
      setDriverArrivalId(response.data.id ?? -1)
      setDriverId(response.data.driver?.id ?? -1)
      setTransition(response.data.plannedTransition)
      setDateTime(response.data.arrivalDateTime)
      if (response.data.plannedTransition?.id) {
        uploadedFileService.getSignetPhoto(response.data.plannedTransition.id).then((response) => {
          setSignetPhoto(response.data.content ?? '')
          setLoadingSignetPhoto(false)
        })
      } else {
        setLoadingSignetPhoto(false)
      }
    })
  }, [idDriverArrival])

  useEffect(() => {
    if (!!dateTime && !transition) {
      loadTransition()
    }
  }, [dateTime, transition, loadTransition])

  // for render the page only once (not blinking)
  useEffect(() => {
    if (!!dateTime && !!transition) {
      setLoaded(true)
    }
  }, [dateTime, transition])

  const handleNewPhoto = (newPhoto: string) => {
    setSignetPhoto(newPhoto)
    setSignedPhotoChanged(true)
  }

  const uploadSignetPhoto = (transitionId: number) => {
    if (signetPhotoChanged) {
      if (signetPhoto === '') {
        uploadedFileService.deleteSignetPhoto(transitionId)
      } else {
        uploadedFileService.assignSignetPhoto(transitionId, { content: signetPhoto })
      }
    }
  }

  const closeNoReferenceDialog = () => {
    setShowNoReferenceDialog(false)
    if (transition) {
      save(transition)
    }
  }

  const onSubmit = (data: TransitionDto) => {
    const values = {
      ...transition,
      ...data,
      container: {
        ...transition?.container,
        ...data.container,
      },
      transitionAdditionalInfo: {
        ...transition?.transitionAdditionalInfo,
        ...data.transitionAdditionalInfo,
      },
    }

    console.log('Submitting form with values:', values)

    if (
      values.repleted
        ? !values.bookingReference || values.bookingReference === ''
        : !values.transitionAdditionalInfo.medlogReference || values.transitionAdditionalInfo.medlogReference === ''
    ) {
      setTransition(values)
      setShowNoReferenceDialog(true)
    } else {
      save(values)
    }
  }

  const save = (values: TransitionDto) => {
    if (!!values.id) {
      transitionService
        .updateByDriver(values.id, driverArrivalId, values)
        .then((response) => {
          uploadSignetPhoto(response.data.id ?? -1)
          history.push('/home')
        })
        .catch((error) => {
          if (error.response && error.response.status === 403) {
            console.error('403 Forbidden error:', error)
            history.push('/home')
          } else {
            console.error('Error:', error)
          }
        })
    } else {
      gateInService
        .createByDriver(driverArrivalId, values)
        .then((response) => {
          uploadSignetPhoto(response.data.id ?? -1)
          history.push('/home')
        })
        .catch((error) => {
          console.error('Error:', error)
        })
    }
  }

  return (
    <>
      <Box>
        {loaded && !!transition && (
          <TransitionForm
            initialValues={transition}
            onSubmit={onSubmit}
            formMode={FormViewMode.DRIVER}
            driverId={driverId}
            signetPhoto={signetPhoto}
            loadingSignetPhoto={loadingSignetPhoto}
            handleNewPhoto={handleNewPhoto}
            submitButtonText={t('driverApp.form.buttons.planGateIn')}
          />
        )}
      </Box>
      <Dialog open={showNoReferenceDialog} onClose={closeNoReferenceDialog}>
        <DialogTitle>{t('driverApp.form.dialog.noReference.title')}</DialogTitle>
        <DialogContent>{t('driverApp.form.dialog.noReference.content')}</DialogContent>
        <DialogActions>
          <ConardButton conardVariant="dark" className={classes.button} onClick={closeNoReferenceDialog}>
            {t('any.buttons.ok')}
          </ConardButton>
        </DialogActions>
      </Dialog>
    </>
  )
}
