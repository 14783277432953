import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface SignetPhotoState {
  signetPhoto: string
  signetPhotoChanged: boolean
}

export const signetPhotoDefaults: SignetPhotoState = {
  signetPhoto: '',
  signetPhotoChanged: false,
}

export const signetPhotoSlice = createSlice({
  name: 'signetPhoto',
  initialState: signetPhotoDefaults,
  reducers: {
    setInitialSignetPhoto(state: SignetPhotoState, action: PayloadAction<string>) {
      state.signetPhoto = action.payload
      state.signetPhotoChanged = false
    },
    changeSignetPhoto(state: SignetPhotoState, action: PayloadAction<string>) {
      state.signetPhoto = action.payload
      state.signetPhotoChanged = true
    },
  },
})

export const { setInitialSignetPhoto, changeSignetPhoto } = signetPhotoSlice.actions
