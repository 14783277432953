import React, { FC, useEffect } from 'react'
import { ConardAccordion, ConardAccordionSummary } from '../../../components/transition/ConardAccordion'
import { Grid, makeStyles } from '@material-ui/core'
import { AccordionDetails } from '@mui/material'
import { ConardLabel } from '../../../components/transition/DriverFields'
import { useTranslation } from 'react-i18next'
import { RHFSwitch } from '../../../components/shared/rhf/RHFSwitch'
import { useFormContext, useWatch } from 'react-hook-form'
import { NhmField } from './NhmField'
import { ContainerDtoContainerTypeEnum, NhmCodeDto } from '../../../api'
import { ContainerWeightField } from '../container/ContainerWeightField'
import { SignetField } from './SignetField'
import { FormViewMode } from '../../../enums/FormViewMode'

const useStyles = makeStyles((theme) => ({
  labelRepleted: {
    color: theme.palette.primary.main,
  },
  labelEmpty: {
    color: 'gray',
  },
  gridItem: {
    width: '100%',
  },
}))

interface RepletedBoxProps {
  signetPhoto: string
  loadingSignetPhoto: boolean
  handleNewPhoto: (photo: string) => void
  initialNhmCodes?: NhmCodeDto[]
  containerType: ContainerDtoContainerTypeEnum
  formMode: FormViewMode
}

export const RepletedBox: FC<RepletedBoxProps> = ({
  signetPhoto,
  loadingSignetPhoto,
  handleNewPhoto,
  initialNhmCodes,
  containerType,
  formMode,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { control } = useFormContext()

  const isRepleted = useWatch({
    control: control,
    name: 'repleted',
  })

  useEffect(() => {
    if (!isRepleted) {
      control.setValue('signetValue', '')
      control.setValue('netWeight', '')
    }
  }, [isRepleted, control])

  return (
    <ConardAccordion expanded={!!isRepleted}>
      <ConardAccordionSummary>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item className={isRepleted ? classes.labelRepleted : classes.labelEmpty}>
            {isRepleted ? t('form.transitionForm.repleted') : t('form.transitionForm.notRepleted')}
          </Grid>
          <Grid item>
            <RHFSwitch label="" name="repleted" />
          </Grid>
        </Grid>
      </ConardAccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          {formMode !== FormViewMode.EXTERN && (
            <Grid item className={classes.gridItem}>
              <ConardLabel>
                <span className={isRepleted ? classes.labelRepleted : classes.labelEmpty}>
                  {t('form.transitionForm.nhmCodes')}
                </span>
              </ConardLabel>
              <NhmField isDisabled={!isRepleted} initialCodes={initialNhmCodes} containerType={containerType} />
            </Grid>
          )}

          <Grid item className={classes.gridItem}>
            <SignetField
              signetPhoto={signetPhoto}
              loadingSignetPhoto={loadingSignetPhoto}
              handleNewPhoto={handleNewPhoto}
            />
          </Grid>

          <Grid item className={classes.gridItem}>
            <ContainerWeightField />
          </Grid>
        </Grid>
      </AccordionDetails>
    </ConardAccordion>
  )
}
