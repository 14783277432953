import { Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import React, { FC } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import ConardButton from '../../../components/ConardButton'

const useStyles = makeStyles(() => ({
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '20px',
    margin: 'auto',
    marginTop: '40px',
    maxWidth: '800px',
  },
  button: {
    width: '250px',
    height: '60px',
    borderRadius: 12,
    fontWeight: 500,
    fontSize: 'large',
  },
}))

interface WrongIluDialogProps {
  open: boolean
  iluCode: string
  onClose: () => void
  onContinue: () => void
}

export const WrongIluDialog: FC<WrongIluDialogProps> = ({ open, onClose, onContinue, iluCode }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Dialog open={open}>
      <DialogTitle>{t('kioskApp.step2.dialog.dialogHeader')}</DialogTitle>
      <DialogContent>
        <Trans i18nKey="kioskApp.step2.dialog.dialogMessage" values={{ iluCode }} components={{ strong: <strong /> }} />
      </DialogContent>
      <DialogActions>
        <div className={classes.buttonsWrapper}>
          <ConardButton conardVariant="transparent" className={classes.button} onClick={onClose} startIcon={<Close />}>
            {t('kioskApp.buttons.cancel')}
          </ConardButton>
          <ConardButton conardVariant="dark" className={classes.button} onClick={onContinue}>
            {t('kioskApp.buttons.continue')}
          </ConardButton>
        </div>
      </DialogActions>
    </Dialog>
  )
}
