import { ContainerDto } from '../../../api'
import { GateInFormValues } from './initialValues'

export const mapValues = (entity: ContainerDto | undefined): GateInFormValues | undefined => {
  if (!entity) return undefined

  return {
    container: {
      iluCode: entity.iluCode ?? '',
      shippingContainerType: entity.shippingContainerType ?? '',
      teu: entity.teu ?? 0,
      tarraWeight: entity.tarraWeight ?? undefined,
      containerType: entity.containerType ?? '',
      semitrailerType: entity.semitrailerType ?? '',
      semitrailerLicencePlate: entity.semitrailerLicencePlate ?? '',
      company: {
        id: entity.company?.id ?? 0,
        parentCompanyId: entity.company?.parentCompanyId ?? 0,
      },
      shipowner: {
        id: entity.shipowner?.id ?? 0,
      },
    },
  }
}
