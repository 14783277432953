import { ConardLabel } from '../../../components/transition/DriverFields'
import { ContainerDtoContainerTypeEnum } from '../../../api'
import { FormViewMode } from '../../../enums/FormViewMode'
import ConardTextField from '../../../components/ConardTextField'
import React, { FC } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface ReferenceFieldProps {
  initialId?: number
  initialContainerType?: ContainerDtoContainerTypeEnum
  formMode: FormViewMode
}

export const ReferenceField: FC<ReferenceFieldProps> = ({ initialId, initialContainerType, formMode }) => {
  const { t } = useTranslation()
  const { control, errors } = useFormContext()

  const isRepleted = useWatch({
    control,
    name: 'repleted',
  })

  return (
    <>
      <ConardLabel
        inactive={
          initialContainerType === ContainerDtoContainerTypeEnum.ShippingContainer &&
          formMode === FormViewMode.DRIVER &&
          (!!isRepleted || !!initialId)
        }
      >
        {t('form.transitionForm.reference')}
      </ConardLabel>
      {isRepleted ? (
        <ConardTextField
          roundedSquare
          name="bookingReference"
          defaultValue=""
          error={!!errors.bookingReference}
          helperText={errors.bookingReference?.message ?? ''}
          control={control}
          disabled={
            initialContainerType === ContainerDtoContainerTypeEnum.ShippingContainer &&
            formMode === FormViewMode.DRIVER &&
            (!!isRepleted || !!initialId)
          }
        />
      ) : (
        <ConardTextField
          roundedSquare
          name="transitionAdditionalInfo.medlogReference"
          defaultValue=""
          error={!!errors.transitionAdditionalInfo?.medlogReference}
          helperText={errors.transitionAdditionalInfo?.medlogReference?.message ?? ''}
          control={control}
          disabled={
            initialContainerType === ContainerDtoContainerTypeEnum.ShippingContainer &&
            formMode === FormViewMode.DRIVER &&
            !!initialId
          }
        />
      )}
    </>
  )
}
