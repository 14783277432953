import React, { FC } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import ConardButton from '../ConardButton'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles({
  closeIcon: {
    position: 'absolute',
    top: '10px',
    right: '10px',
  },
  button: {
    borderRadius: 12,
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  signetPhoto: {
    maxWidth: '100%',
  },
})

interface SignetPhotoDetailProps {
  signetPhoto: string
  handleAddPhoto: () => void
  handleDeletePhoto: () => void
  showPhotoDetail: boolean
  handleShowPhotoDetail: () => void
  disabled?: boolean
}

export const SignetPhotoDetail: FC<SignetPhotoDetailProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { signetPhoto, handleAddPhoto, handleDeletePhoto, showPhotoDetail, handleShowPhotoDetail, disabled } = props

  return (
    <Dialog open={showPhotoDetail} onClose={handleShowPhotoDetail} fullWidth maxWidth="lg">
      <DialogTitle>
        {t('form.transitionForm.signet')}
        <IconButton className={classes.closeIcon} onClick={handleShowPhotoDetail}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <img src={signetPhoto} alt="" className={classes.signetPhoto} />
      </DialogContent>
      <DialogActions>
        {!disabled && (
          <>
            <ConardButton conardVariant="dark" onClick={handleAddPhoto} className={classes.button}>
              {t('any.buttons.change')}
            </ConardButton>
            <ConardButton conardVariant="red" onClick={handleDeletePhoto} className={classes.button}>
              {t('any.buttons.delete')}
            </ConardButton>
          </>
        )}
      </DialogActions>
    </Dialog>
  )
}
