import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { Autocomplete } from '@material-ui/lab'
import { ExtraDto } from '../../../api'
import ConardTooltip from '../../../components/ConardTooltip'
import { Chip, createStyles, TextField } from '@material-ui/core'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import transitionExtraService from '../../../services/TransitionExtraService'
import { ConardLabel } from '../../../components/transition/DriverFields'

const useStyles = makeStyles((theme) =>
  createStyles({
    autocomplete: {
      width: '100%',
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: 'translate(34px, -6px) scale(0.75)',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        '& legend': {
          marginLeft: '20px',
        },
      },
      '& .MuiAutocomplete-endAdornment': {
        right: '7px',
      },
    },
    inputAutocomplete: {
      borderRadius: 12,
      borderColor: theme.palette.secondary.light,
      backgroundColor: theme.palette.secondary.main,
      display: 'table',
      paddingRight: 60,
      height: 50,
      maxHeight: 200,
    },
    chipLabel: {
      maxWidth: '19vw',
    },
    divWrapper: {
      backgroundColor: 'white',
      padding: '20px',
      borderRadius: '10px',
      boxShadow: '0 2px 4px 0 lightGray',
    },
  })
)

interface ExtrasFieldProps {
  initialExtras?: ExtraDto[]
}

export const ExtrasField: FC<ExtrasFieldProps> = ({ initialExtras }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { control, errors } = useFormContext()

  const [showExtrasSelection, setShowExtrasSelection] = useState(false)
  const [extras, setExtras] = useState<ExtraDto[]>([])

  useEffect(() => {
    transitionExtraService.findAll(0, 100, '', '').then((response) => {
      setExtras(response.data.content ?? [])
    })
  }, [])

  const handleExtrasSelection = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.target.value !== '') {
      setShowExtrasSelection(true)
    } else {
      setShowExtrasSelection(false)
    }
  }

  const onChangeExtras = (_: React.ChangeEvent<{}>, value: ExtraDto[]) => {
    control.setValue('extras', value)
    setShowExtrasSelection(false)
  }

  return (
    <div className={classes.divWrapper}>
      <ConardLabel>{t('form.transitionForm.extras')}</ConardLabel>
      <Controller
        id="extras"
        name="extras"
        defaultValue={[]}
        control={control}
        render={() => (
          <Autocomplete
            multiple
            filterSelectedOptions
            limitTags={6}
            options={showExtrasSelection ? extras : []}
            defaultValue={initialExtras ?? []}
            noOptionsText={showExtrasSelection ? t('any.noOptions') : t('any.startTyping')}
            getOptionLabel={(option) => (option as ExtraDto).name ?? ''}
            onChange={(event, data) => onChangeExtras(event, data)}
            renderTags={(tagValue: ExtraDto[], getTagProps) =>
              tagValue.map((option, index) => (
                <>
                  <ConardTooltip title={option.name ?? ''}>
                    <Chip
                      classes={{ label: classes.chipLabel }}
                      key={option.name}
                      label={option.name}
                      {...getTagProps({ index })}
                    />
                  </ConardTooltip>
                </>
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label=""
                variant="outlined"
                error={!!errors.extras}
                helperText={errors.extras ? t('pages.transitionAdditionalServices.validation.label') : ''}
                InputProps={{
                  ...params.InputProps,
                  className: classes.inputAutocomplete,
                }}
                onChange={(event) => handleExtrasSelection(event)}
                onBlur={() => setShowExtrasSelection(false)}
                className={classes.autocomplete}
              />
            )}
          />
        )}
      />
    </div>
  )
}
