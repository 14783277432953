import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Autocomplete } from '@material-ui/lab'
import { ContainerDtoContainerTypeEnum, NhmCodeDto } from '../../../api'
import ConardTooltip from '../../../components/ConardTooltip'
import { Chip, makeStyles, TextField } from '@material-ui/core'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import nhmService from '../../../services/NhmService'
import i18next from 'i18next'

const useStyles = makeStyles((theme) => ({
  chip: {
    maxWidth: '180px',
  },
  nhmInputAutocomplete: {
    borderRadius: 12,
    borderColor: theme.palette.secondary.light,
    backgroundColor: theme.palette.secondary.main,
    display: 'table',
    paddingRight: 60,
    height: 50,
    maxHeight: 200,
  },
  autocomplete: {
    width: '100%',
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(34px, -6px) scale(0.75)',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      '& legend': {
        marginLeft: '20px',
      },
    },
    '& .MuiAutocomplete-endAdornment': {
      right: '7px',
    },
  },
}))

interface NhmFieldProps {
  isDisabled: boolean
  initialCodes?: NhmCodeDto[]
  containerType?: ContainerDtoContainerTypeEnum
  error?: boolean
  helperText?: string
}

export const NhmField: FC<NhmFieldProps> = ({ isDisabled, initialCodes, containerType, error, helperText }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { control } = useFormContext()

  const [nhmCodes, setNhmCodes] = useState<NhmCodeDto[]>([])
  const [selectedNhmCodes, setSelectedNhmCodes] = useState<NhmCodeDto[]>(initialCodes ?? [])
  const [showOptions, setShowOptions] = useState(false)

  useEffect(() => {
    nhmService.findAllCodes(0, 20000, undefined).then((response) => {
      setNhmCodes(response.data.content ?? [])
    })
  }, [])

  const onChangeNhmCodes = (event: React.ChangeEvent<{}>, value: NhmCodeDto[]) => {
    control.setValue('nhmCodes', value)
    setSelectedNhmCodes(value)
  }

  const handleTextChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setShowOptions((event.target.value as string).length > 1)
  }

  const isRepleted = useWatch({
    control,
    name: 'repleted',
  })

  useEffect(() => {
    const defaultNhmCodeNotRepleted = {
      id: 12591,
      code: '99330000',
      nameCz: 'Návěsy, prázdné použité a před nebo po loženém běhu po železnici',
      noteCz: 'Návěsy, prázdné použité a před nebo po loženém běhu po železnici',
      nameEn: 'Semi trailers, empty, used, before or after a transport by rail with payload.',
      noteEn: 'Semi trailers, empty, used, before or after a transport by rail with payload.',
    }

    if (
      containerType === ContainerDtoContainerTypeEnum.Semitrailer &&
      (selectedNhmCodes.length === 0 || selectedNhmCodes[0].code === defaultNhmCodeNotRepleted.code)
    ) {
      const defaultNhmCodes = isRepleted
        ? [
            {
              id: 12597,
              code: '99430000',
              nameCz: 'Návěsy, ložené',
              noteCz: 'Návěsy, ložené',
              nameEn: 'Semi trailers, loaded',
              noteEn: 'Semi trailers, loaded',
            },
          ]
        : [defaultNhmCodeNotRepleted]

      control.setValue('nhmCodes', defaultNhmCodes)
      setSelectedNhmCodes(defaultNhmCodes)
    } else if (
      !isRepleted &&
      selectedNhmCodes[0]?.code !== defaultNhmCodeNotRepleted.code &&
      containerType === ContainerDtoContainerTypeEnum.Semitrailer
    ) {
      control.setValue('nhmCodes', [defaultNhmCodeNotRepleted])
      setSelectedNhmCodes([defaultNhmCodeNotRepleted])
    } else if (!isRepleted && containerType === ContainerDtoContainerTypeEnum.ShippingContainer) {
      control.setValue('nhmCodes', [])
      setSelectedNhmCodes([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRepleted, containerType])

  const getOptionName = (option: NhmCodeDto) => {
    return i18next.language === 'cs' ? option.nameCz ?? '' : option.nameEn ?? ''
  }

  return (
    <Controller
      id="nhmCodes"
      name="nhmCodes"
      defaultValue={[]}
      control={control}
      render={() => (
        <Autocomplete
          multiple
          filterSelectedOptions
          limitTags={6}
          options={showOptions ? nhmCodes : []}
          value={selectedNhmCodes}
          noOptionsText={showOptions ? t('any.noOptions') : t('any.startTyping')}
          getOptionLabel={(option) => (option as NhmCodeDto).code + ' - ' + getOptionName(option) ?? ''}
          disabled={isDisabled}
          onChange={(event, data) => onChangeNhmCodes(event, data)}
          renderTags={(tagValue: NhmCodeDto[], getTagProps) =>
            tagValue.map((option, index) => (
              <>
                <ConardTooltip title={option.code + ' - ' + getOptionName(option)}>
                  <Chip
                    classes={{ label: classes.chip }}
                    key={option.code}
                    label={option.code + ' - ' + getOptionName(option)}
                    color="primary"
                    {...getTagProps({ index })}
                  />
                </ConardTooltip>
              </>
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              disabled={isDisabled}
              className={classes.autocomplete}
              error={error ?? false}
              helperText={helperText}
              onChange={(event) => handleTextChange(event)}
              InputProps={{
                ...params.InputProps,
                className: classes.nhmInputAutocomplete,
              }}
            />
          )}
        />
      )}
    />
  )
}
