import React, { FC } from 'react'
import { ContainerDtoContainerTypeEnum } from '../../../api'
import { FormViewMode } from '../../../enums/FormViewMode'
import { makeStyles, Typography } from '@material-ui/core'
import { SemitrailerIcon } from '../../../assets/icons/SemitrailerIcon'
import ConardTextField from '../../../components/ConardTextField'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ContainerIcon } from '../../../assets/icons/ContainerIcon'
import { ConardLabel } from '../../../components/transition/DriverFields'

const useStyles = makeStyles((theme) => ({
  containerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '4rem 0',
    [theme.breakpoints.up('lg')]: {
      margin: '10rem 0',
    },
  },
  container: {
    scale: 12,
    color: theme.palette.primary.main,
    [theme.breakpoints.up('lg')]: {
      scale: 24,
    },
  },
  semitrailer: {
    margin: '1rem 0',
  },
  iluCodeDiv: {
    position: 'absolute',
    marginTop: '-15px',
    backgroundColor: theme.palette.background.default,
    zIndex: 2,
    width: '12.5rem',
    height: '4rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    justifyContent: 'center',
    [theme.breakpoints.up('lg')]: {
      width: '25rem',
      height: '8rem',
    },
  },
  iluCodeDivSemitrailer: {
    marginTop: '-40px',
  },
  iluCode: {
    fontSize: '26px',
    fontWeight: 750,
  },
}))

interface ContainerHeaderIconProps {
  iluCode: string
  containerType: ContainerDtoContainerTypeEnum
  formMode: FormViewMode
}

export const ContainerHeaderIcon: FC<ContainerHeaderIconProps> = ({ iluCode, containerType, formMode }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { control, errors } = useFormContext()

  return (
    <div className={classes.containerWrapper}>
      {containerType === ContainerDtoContainerTypeEnum.ShippingContainer ? (
        <ContainerIcon className={classes.container} />
      ) : (
        <SemitrailerIcon className={`${classes.container} ${classes.semitrailer}`} />
      )}

      <div
        className={
          containerType === ContainerDtoContainerTypeEnum.ShippingContainer
            ? classes.iluCodeDiv
            : `${classes.iluCodeDiv} ${classes.iluCodeDivSemitrailer}`
        }
      >
        <ConardLabel>{t('form.containerForm.iluCode')}</ConardLabel>
        {formMode === FormViewMode.EXTERN ? (
          <ConardTextField
            roundedSquare
            name="container.iluCode"
            control={control}
            defaultValue=""
            error={!!errors.container?.iluCode}
            helperText={errors.container?.iluCode?.message ?? ''}
          />
        ) : (
          <Typography className={classes.iluCode}>{iluCode}</Typography>
        )}
      </div>
    </div>
  )
}
