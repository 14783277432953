import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import { ContainerDtoContainerTypeEnum } from '../../../api'
import { FormViewMode } from '../../../enums/FormViewMode'

export const ValidationScheme = (
  formMode: FormViewMode,
  containerType: ContainerDtoContainerTypeEnum = ContainerDtoContainerTypeEnum.ShippingContainer
) => {
  const { t } = useTranslation()

  const shippingContainerTypeValidation =
    containerType === ContainerDtoContainerTypeEnum.ShippingContainer
      ? yup.string().not([''], t('pages.gateIn.validations.shippingContainerType.notSelected'))
      : yup.string().notRequired()

  const semitrailerTypeValidation =
    containerType === ContainerDtoContainerTypeEnum.Semitrailer
      ? yup.string().not([''], t('pages.gateIn.validations.semitrailerType.notSelected'))
      : yup.string().notRequired()

  const shipownerValidation =
    containerType === ContainerDtoContainerTypeEnum.ShippingContainer
      ? yup.object().shape({
          id: yup
            .number()
            .typeError(t('pages.gateIn.validations.shipowner.notSelected'))
            .positive(t('pages.gateIn.validations.shipowner.notSelected')),
        })
      : yup.object().notRequired()

  const companyValidation = yup.object().shape({
    id: yup
      .number()
      .typeError(t('pages.gateIn.validations.companyId.notSelected'))
      .positive(t('pages.gateIn.validations.companyId.notValid')),
    parentCompanyId: yup
      .number()
      .typeError(t('pages.gateIn.validations.parentCompanyId.notSelected'))
      .positive(t('pages.gateIn.validations.parentCompanyId.notValid')),
  })

  const fullValidation = yup.object().shape({
    container: yup.object().shape({
      iluCode: yup.string().required(t('pages.gateIn.validations.iluCode.required')),
      shipowner: shipownerValidation,
      company: companyValidation,
      shippingContainerType: shippingContainerTypeValidation,
      semitrailerType: semitrailerTypeValidation,
      tarraWeight: yup
        .number()
        .typeError(t('pages.gateIn.validations.tarraWeight.notSelected'))
        .positive(t('pages.gateIn.validations.tarraWeight.notPositive')),
      maxGross: yup
        .number()
        .typeError(t('pages.gateIn.validations.maxGross.required'))
        .positive(t('pages.gateIn.validations.maxGross.required')),
      heavyTestedValue: yup.mixed().when('heavyTested', {
        is: true,
        then: yup
          .number()
          .typeError(t('pages.gateIn.validations.heavyTestedValue.notSelected'))
          .positive(t('pages.gateIn.validations.heavyTestedValue.notPositive')),
        otherwise: yup.mixed(),
      }),
    }),
    netWeight: yup.mixed().when('repleted', {
      is: true,
      then: yup
        .number()
        .typeError(t('pages.gateIn.validations.netWeight.notSelected'))
        .positive(t('pages.gateIn.validations.netWeight.notPositive')),
      otherwise: yup.mixed(),
    }),
    bookingReference:
      formMode === FormViewMode.EXTERN
        ? yup.string().notRequired()
        : yup.string().when('repleted', {
            is: true,
            then: yup.string().required(t('pages.gateIn.validations.bookingReference')),
            otherwise: yup.string().notRequired(),
          }),
    transitionAdditionalInfo:
      formMode === FormViewMode.EXTERN
        ? yup.object().notRequired()
        : yup.object().when('repleted', {
            is: false,
            then: yup.object().shape({
              medlogReference: yup.string().required(t('pages.gateIn.validations.medlogReference')),
            }),
            otherwise: yup.object().notRequired(),
          }),
  })

  const driverValidation = yup.object().shape({
    container: yup.object().shape({
      shipowner: shipownerValidation,
      company: companyValidation,
      shippingContainerType: shippingContainerTypeValidation,
      semitrailerType: semitrailerTypeValidation,
    }),
  })

  const kioskValidation = yup.object().shape({
    container: yup.object().shape({
      shipowner: shipownerValidation,
      company: companyValidation,
      shippingContainerType: shippingContainerTypeValidation,
      semitrailerType: semitrailerTypeValidation,
    }),
  })

  if (formMode === FormViewMode.DRIVER) {
    return driverValidation
  } else if (formMode === FormViewMode.KIOSK) {
    return kioskValidation
  } else {
    return fullValidation
  }
}
