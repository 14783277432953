import { Box, createStyles, Divider, Grid, List, makeStyles } from '@material-ui/core'
import GroupIcon from '@material-ui/icons/Group'
import EuroIcon from '@material-ui/icons/Euro'
import React, { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { UserDtoRoleEnum } from '../../api'
import { ConfigContext } from '../../context/ConfigContext'
import MainMenuListItemLink from './MainMenuListItemLink'
import gateOutIcon from '../../assets/images/gateOutIcon.png'
import gateInIcon from '../../assets/images/gateInIcon.png'
import SearchIcon from '@material-ui/icons/Search'
import HistoryIcon from '@material-ui/icons/History'
import FindInPage from '@material-ui/icons/FindInPage'
import TrainIcon from '@material-ui/icons/Train'
import MapIcon from '@mui/icons-material/Map'
import PolicyOutlinedIcon from '@material-ui/icons/PolicyOutlined'
import ListAltIcon from '@material-ui/icons/ListAlt'
import { Business, LocalShipping, SaveAlt } from '@material-ui/icons'
import { useConardAuth } from '../../hooks/useConardAuth'
import DriveEtaIcon from '@material-ui/icons/DriveEta'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'

const useStyles = makeStyles(() =>
  createStyles({
    menuItemList: {
      width: '100%',
    },
  })
)

interface MenuItem {
  to: string
  icon: React.ReactNode
  text: string
  roles?: Set<UserDtoRoleEnum>
  enabled?: boolean
}

const MainMenuButtonItems: FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const auth = useConardAuth()
  const configContext = useContext(ConfigContext)

  const mainMenu: MenuItem[] = [
    {
      to: '/operator',
      icon: <ListAltIcon fontSize="small" color="secondary" />,
      text: t('mainMenu.pages.operator.title'),
      roles: new Set<UserDtoRoleEnum>([UserDtoRoleEnum.Operator]),
    },
    {
      to: '/driver-arrival',
      icon: <DriveEtaIcon fontSize="small" color="secondary" />,
      text: t('mainMenu.pages.driver.title'),
      roles: new Set<UserDtoRoleEnum>([UserDtoRoleEnum.Driver]),
    },
    {
      to: '/carrier',
      icon: <LocalShippingIcon fontSize="small" color="secondary" />,
      text: t('mainMenu.pages.carrier.title'),
      roles: new Set<UserDtoRoleEnum>([UserDtoRoleEnum.Carrier]),
    },
    {
      to: '/carrier/search',
      icon: <SearchIcon fontSize="small" color="secondary" />,
      text: t('mainMenu.pages.searchTransition.title'),
      roles: new Set<UserDtoRoleEnum>([UserDtoRoleEnum.Carrier]),
    },
    {
      to: '/gate-in',
      icon: <img src={gateInIcon} alt="gateInIcon" />,
      text: t('mainMenu.pages.gateIn.title'),
      roles: new Set<UserDtoRoleEnum>([UserDtoRoleEnum.Dispatcher, UserDtoRoleEnum.DispatcherRoad]),
    },
    {
      to: '/gate-out',
      icon: <img src={gateOutIcon} alt="gateOutIcon" />,
      text: t('mainMenu.pages.gateOut.title'),
      roles: new Set<UserDtoRoleEnum>([UserDtoRoleEnum.Dispatcher, UserDtoRoleEnum.DispatcherRoad]),
    },
    {
      to: '/user',
      icon: <GroupIcon fontSize="small" color="secondary" />,
      text: t('mainMenu.pages.user.title'),
      roles: new Set<UserDtoRoleEnum>([UserDtoRoleEnum.Manager]),
    },
    {
      to: '/customer',
      icon: <Business fontSize="small" color="secondary" />,
      text: t('mainMenu.pages.customers.title'),
      roles: new Set<UserDtoRoleEnum>([UserDtoRoleEnum.Manager]),
    },
    {
      to: '/train',
      icon: <TrainIcon fontSize="small" color="secondary" />,
      text: t('mainMenu.pages.searchTrain.title'),
      roles: new Set<UserDtoRoleEnum>([
        UserDtoRoleEnum.Manager,
        UserDtoRoleEnum.Dispatcher,
        UserDtoRoleEnum.DispatcherTrain,
      ]),
    },
    {
      to: '/search/transition',
      icon: <SearchIcon fontSize="small" color="secondary" />,
      text: t('mainMenu.pages.searchTransition.title'),
      roles: new Set<UserDtoRoleEnum>([
        UserDtoRoleEnum.Manager,
        UserDtoRoleEnum.Dispatcher,
        UserDtoRoleEnum.DispatcherRoad,
        UserDtoRoleEnum.DispatcherTrain,
      ]),
    },
    {
      to: '/search/processed-files',
      icon: <FindInPage fontSize="small" color="secondary" />,
      text: t('mainMenu.pages.searchProcessedFiles.title'),
      roles: new Set<UserDtoRoleEnum>([
        UserDtoRoleEnum.Manager,
        UserDtoRoleEnum.Dispatcher,
        UserDtoRoleEnum.DispatcherRoad,
        UserDtoRoleEnum.DispatcherTrain,
      ]),
    },
    {
      to: '/expenses',
      icon: <EuroIcon fontSize="small" color="secondary" />,
      text: t('mainMenu.pages.expenses.title'),
      roles: new Set<UserDtoRoleEnum>([UserDtoRoleEnum.Manager]),
    },
    {
      to: '/history',
      icon: <HistoryIcon fontSize="small" color="secondary" />,
      text: t('mainMenu.pages.history.title'),
      roles: new Set<UserDtoRoleEnum>([UserDtoRoleEnum.Manager]),
    },
    {
      to: '/entry-and-parking',
      icon: <LocalShipping fontSize="small" color="secondary" />,
      text: t('mainMenu.pages.entryAndParking.title'),
      roles: new Set<UserDtoRoleEnum>([
        UserDtoRoleEnum.Manager,
        UserDtoRoleEnum.Dispatcher,
        UserDtoRoleEnum.DispatcherRoad,
        UserDtoRoleEnum.DispatcherTrain,
      ]),
      enabled: configContext.entryAndParking,
    },
    {
      to: '/export-config',
      icon: <SaveAlt fontSize="small" color="secondary" />,
      text: t('mainMenu.pages.exportConfig.title'),
      roles: new Set<UserDtoRoleEnum>([UserDtoRoleEnum.Manager]),
    },
    {
      to: '/customs',
      icon: <PolicyOutlinedIcon fontSize="small" color="secondary" />,
      text: t('mainMenu.pages.customsSummary.title'),
      roles: new Set<UserDtoRoleEnum>([UserDtoRoleEnum.CustomsOfficer]),
    },
    {
      to: '/customs/history',
      icon: <HistoryIcon fontSize="small" color="secondary" />,
      text: t('mainMenu.pages.history.title'),
      roles: new Set<UserDtoRoleEnum>([UserDtoRoleEnum.CustomsOfficer]),
    },
    {
      to: '/terminal-layout',
      icon: <MapIcon fontSize="small" color="inherit" />,
      text: t('mainMenu.pages.layout.title'),
      roles: new Set<UserDtoRoleEnum>([UserDtoRoleEnum.Dispatcher]),
      enabled: configContext.terminalLayout,
    },
    {
      to: '/driver',
      icon: <DriveEtaIcon fontSize="small" color="secondary" />,
      text: t('mainMenu.pages.drivers.title'),
      roles: new Set<UserDtoRoleEnum>([UserDtoRoleEnum.Carrier]),
    },
    {
      to: '/trains',
      icon: <TrainIcon fontSize="small" color="secondary" />,
      text: t('mainMenu.pages.trains.title'),
      roles: new Set<UserDtoRoleEnum>([UserDtoRoleEnum.Carrier, UserDtoRoleEnum.Driver]),
    },
  ]

  return (
    <Grid container direction="column" alignItems="center">
      <List disablePadding className={classes.menuItemList}>
        <Grid item>
          {mainMenu.map(
            (menuItem) =>
              auth.hasOneOfRoles(menuItem.roles) &&
              (menuItem.enabled === undefined || menuItem.enabled) && (
                <Box key={menuItem.text}>
                  <MainMenuListItemLink text={menuItem.text} icon={menuItem.icon} to={menuItem.to} />
                  <Divider light />
                </Box>
              )
          )}
        </Grid>
      </List>
    </Grid>
  )
}

export default MainMenuButtonItems
