import {
  ContainerDtoContainerTypeEnum,
  ContainerDtoSemitrailerTypeEnum,
  ContainerDtoShippingContainerTypeEnum,
} from '../../../api'

export type GateInFormValues = {
  container: {
    iluCode: string
    shippingContainerType: ContainerDtoShippingContainerTypeEnum | string
    containerType: ContainerDtoContainerTypeEnum | string
    tarraWeight: number | undefined
    teu: number
    semitrailerLicencePlate?: string
    semitrailerType: ContainerDtoSemitrailerTypeEnum | string
    shipowner: {
      id: number
    }
    company: {
      id: number
      parentCompanyId?: number
    }
  }
}

export const initialValues: GateInFormValues = {
  container: {
    iluCode: '',
    shippingContainerType: '',
    semitrailerType: '',
    tarraWeight: undefined,
    containerType: '',
    teu: 0,
    company: {
      id: 0,
    },
    shipowner: {
      id: 0,
    },
  },
}
