import { alpha, createStyles, Grid, makeStyles, Typography } from '@material-ui/core'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ContainerDtoContainerTypeEnum, TransitionDto } from '../../api'
import { TransitionMode } from '../../enums/TransitionMode'
import { ContainerDamagedIcon } from '../../assets/icons/ContainerDamagedIcon'
import { SemitrailerDamagedIcon } from '../../assets/icons/SemitrailerDamagedIcon'
import ConardButton from '../ConardButton'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import containerService from '../../services/ContainerService'

const useStyles = makeStyles((theme) =>
  createStyles({
    damagePanelContainer: {
      margin: '3rem 0',
      backgroundColor: alpha(theme.palette.secondary.dark, 0.3),
      borderRadius: '8px',
      textAlign: 'center',
      minWidth: '290px',
      padding: '20px 0px 20px 0px',
    },
    damageSummaryGridItem: {
      width: '60%',
    },
    damageSummaryPanel: {
      textAlign: 'center',
      color: theme.palette.secondary.main,
      fontWeight: 900,
      backgroundColor: theme.palette.error.main,
      borderRadius: '4px',
      padding: '6px',
      marginBottom: '20px',
    },
    damageIcon: {
      fontSize: '110px',
      color: theme.palette.primary.main,
    },
    damageContent: {
      marginBottom: '20px',
    },
    damageLogButton: {
      display: 'flex',
      flexDirection: 'column',
      minWidth: '290px',
      textAlign: 'center',
      marginTop: '1vh',
      marginBottom: '1vh',
    },
    redBorder: {
      borderColor: theme.palette.error.main,
      borderStyle: 'solid',
      borderWidth: '3px',
      backgroundColor: 'rgba(255, 0, 0, 0.3)',
    },
    errorText: {
      fontSize: '20px',
      color: theme.palette.error.main,
      textAlign: 'center',
    },
  })
)

interface ConardDamageLogBoxProps {
  transitionMode: TransitionMode
  transitionFormDisabled: boolean
  error?: string
  onOldDamageLogEntry: () => void
  onNewDamageLogEntryRequested: () => void
  onDownloadPdf: () => void
  onExistingOldDamageLogs: (value: boolean) => void
}

export const ConardDamageLogBox: FC<ConardDamageLogBoxProps> = ({
  transitionMode,
  transitionFormDisabled,
  onNewDamageLogEntryRequested,
  onOldDamageLogEntry,
  onDownloadPdf,
  error,
  onExistingOldDamageLogs,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { id, container, damageLogEntries } = useSelector<RootState, TransitionDto>(
    (state) => state.transition.transition
  )

  const [allDamageLogsLength, setAllDamageLogsLength] = useState<number | undefined>(undefined)

  const containsDamageLogEntries = damageLogEntries && damageLogEntries.length > 0

  const loadDamages = () => {
    if (container.id) {
      containerService.getAllDamages(container.id).then((response) => {
        const damageLogEntries = [...response.data]
        setAllDamageLogsLength(damageLogEntries.length)
        onExistingOldDamageLogs(damageLogEntries.length > 0)
      })
    }
  }

  useEffect(() => {
    loadDamages()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={`${classes.damagePanelContainer} ${error ? classes.redBorder : ''}`}
      >
        {containsDamageLogEntries && (
          <Grid item className={classes.damageSummaryGridItem}>
            <div className={classes.damageSummaryPanel}>
              {t('pages.gateIn.headers.currentDamageSummary', {
                length: damageLogEntries?.length,
              })}
            </div>
          </Grid>
        )}
        {(containsDamageLogEntries || transitionMode !== TransitionMode.SEARCH || !transitionFormDisabled) && (
          <Grid item>
            {container.containerType === ContainerDtoContainerTypeEnum.ShippingContainer ? (
              <ContainerDamagedIcon className={classes.damageIcon} />
            ) : (
              <SemitrailerDamagedIcon className={classes.damageIcon} />
            )}
          </Grid>
        )}

        <Grid item>
          <Typography variant="h6" color="primary" className={classes.damageContent}>
            {container.containerType === ContainerDtoContainerTypeEnum.ShippingContainer
              ? t('pages.gateIn.headers.damageShippingContainerHint')
              : t('pages.gateIn.headers.damageSemitrailerHint')}
          </Typography>
        </Grid>
        <Grid item>
          <div className={classes.damageLogButton}>
            <ConardButton conardVariant="light" onClick={onNewDamageLogEntryRequested}>
              {t('pages.gateIn.headers.damageAdd')}
            </ConardButton>
          </div>
        </Grid>
        <Grid item>
          <div className={classes.damageLogButton}>
            <ConardButton conardVariant="light" onClick={onOldDamageLogEntry}>
              {t('pages.gateIn.headers.damageList')}
            </ConardButton>
            {allDamageLogsLength !== undefined && (
              <span>{`${t('pages.gateIn.headers.allDamages')}: ${allDamageLogsLength}`}</span>
            )}
          </div>
        </Grid>
        {id && containsDamageLogEntries && (
          <Grid item>
            <div className={classes.damageLogButton}>
              <ConardButton conardVariant="light" onClick={onDownloadPdf}>
                {t('any.buttons.exportPdf')}
              </ConardButton>
            </div>
          </Grid>
        )}
      </Grid>
      {!!error && <div className={classes.errorText}>{t('pages.gateIn.validations.damageLogEntries')}</div>}
    </>
  )
}
