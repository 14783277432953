import React, { FC } from 'react'
import { createStyles, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import ConardButton from '../../components/ConardButton'
import { Backspace, Clear } from '@material-ui/icons'

const useStyles = makeStyles((theme) =>
  createStyles({
    buttonsWrapper: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '1050px',
    },
    clearButtons: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      marginBottom: '2rem',
    },
    clearButton: {
      margin: '0 2rem 0 2rem',
      height: '50px',
    },
    numbersWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      flexWrap: 'wrap',
    },
    numberButton: {
      height: '12rem',
      width: '12rem',
      margin: '2px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'white',
      fontSize: '2.5rem',
    },
    flashEffect: {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
  })
)

interface NumberDialProps {
  onSelectNumber: (number: number) => void
  onClear: () => void
  onDeleteLast: () => void
}

export const NumberDial: FC<NumberDialProps> = ({ onSelectNumber, onClear, onDeleteLast }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const handleNumberClick = (index: number, event: React.MouseEvent<HTMLDivElement>) => {
    const target = event.currentTarget
    target.classList.add(classes.flashEffect)
    setTimeout(() => {
      target.classList.remove(classes.flashEffect)
    }, 100)

    onSelectNumber(index)
  }

  return (
    <div className={classes.buttonsWrapper}>
      <div className={classes.clearButtons}>
        <ConardButton conardVariant="transparent" className={classes.clearButton} onClick={onClear} endIcon={<Clear />}>
          {t('any.buttons.delete')}
        </ConardButton>
        <ConardButton
          conardVariant="transparent"
          className={classes.clearButton}
          onClick={onDeleteLast}
          endIcon={<Backspace />}
        >
          {t('any.buttons.backspace')}
        </ConardButton>
      </div>
      <div className={classes.numbersWrapper}>
        {Array(10)
          .fill(0)
          .map((_, index) => (
            <div
              key={index}
              className={classes.numberButton}
              onClick={(event) => handleNumberClick((index + 1) % 10, event)}
            >
              {(index + 1) % 10}
            </div>
          ))}
      </div>
    </div>
  )
}
