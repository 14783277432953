import React, { FC, useState } from 'react'
import { Button, createStyles, makeStyles, Theme, Typography } from '@material-ui/core'
import {
  NotificationDto,
  NotificationDtoNotificationTypeEnum,
  TransitionDto,
  TransitionSearchListSearchDto,
  TransitionSearchListSearchDtoContainerStateEnum,
  TransitionSearchListSearchDtoReceiptMethodEnum,
} from '../../api'
import { useTranslation } from 'react-i18next'
import notificationService from '../../services/NotificationService'
import NotificationRemoveDialog from './NotificationRemoveDialog'
import NotificationPlannedGateInDialog from './NotificationPlannedGateInDialog'
import transitionService from '../../services/TransitionService'
import {
  setTransition,
  setTransitionFormDisabled,
  setTransitionPositionDisabled,
  setTransitionSaveDisabled,
  transitionDefaults,
  transitionInitialState,
} from '../../redux/transition/transitionSlice'
import { useHistory } from 'react-router-dom'
import { useAppDispatch } from '../../redux/store'
import { setShowNotifications } from '../../redux/notification/notificationSlice'
import { TransitionMode } from '../../enums/TransitionMode'
import asparkingSemaphoreControlService from '../../services/AsparkingSemaphoreControlService'
import { SemaphoreMode } from '../../enums/SemaphoreMode'
import { AsparkingCheckpoint } from '../../enums/AsparkingCheckpoint'
import asparkingGateControlService from '../../services/AsparkingGateControlService'
import { toast } from 'react-toastify'
import { GateOutTransitionSelectionModal } from '../modals/GateOutTransitionSelectionModal'
import TrainM2mCompareDialog from '../../pages/train/TrainM2mCompareDialog'

export interface NotificationButtonsByNotificationTypeProps {
  notification: NotificationDto
  refreshList: () => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(0.5),
      borderRadius: 20,
    },
  })
)

export const NotificationButtonsByNotificationType: FC<NotificationButtonsByNotificationTypeProps> = ({
  notification,
  refreshList,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useAppDispatch()

  const [showRemoveDialog, setShowRemoveDialog] = useState(false)
  const [showPlannedGateInDialog, setShowPlannedGateInDialog] = useState(false)
  const [showCompareDialog, setShowCompareDialog] = useState(false)
  const [showGateOutModal, setShowGateOutModal] = useState<boolean>(false)
  const [isDeleting, setIsDeleting] = useState(false)

  const handlePlannedGateIn = () => {
    const transitionSearchDto: TransitionSearchListSearchDto = {
      ...getTransitionSearchDto(TransitionSearchListSearchDtoContainerStateEnum.PlannedGateIn),
      receiptMethod: TransitionSearchListSearchDtoReceiptMethodEnum.Road,
    }
    transitionService
      .searchForList(undefined, undefined, undefined, transitionSearchDto)
      .then((response) => {
        const transitionContent = response.data.content ?? []
        const iluCode = getIluCode()
        if (transitionContent.length !== 0 && iluCode) {
          history.push('/search/transition/detail/' + transitionContent[0]?.id, {
            transitionMode: TransitionMode.NOTIFICATION,
            notification,
          })
          toast.dismiss(notification.id)
        } else {
          history.push('/gate-in/pending')
          closeNotification()
        }
        dispatch(setShowNotifications(false))
        setShowPlannedGateInDialog(false)
      })
      .catch()
  }

  const showDetail = (transitionId?: number) => {
    dispatch(setTransitionFormDisabled(true))
    dispatch(setTransitionPositionDisabled(true))
    dispatch(setTransitionSaveDisabled(true))
    history.push('/search/transition/detail/' + transitionId)
  }

  const showDetailFromKiosk = () => {
    if (notification?.id) {
      toast.dismiss(notification.id)
    }
    dispatch(setShowNotifications(false))
    dispatch(setTransitionFormDisabled(false))
    dispatch(setTransitionPositionDisabled(true))
    dispatch(setTransitionSaveDisabled(false))
    dispatch(setTransition(transitionInitialState.transition))
    history.push('/search/transition/detail/' + notification.driverArrival?.plannedTransition?.id ?? -1, {
      notification: notification,
    })
  }

  const handleGateOut = () => {
    const transitionSearchDtoBefore2Nd = getTransitionSearchDto(
      TransitionSearchListSearchDtoContainerStateEnum.Before2NdCheckpointGo
    )
    const transitionSearchDtoBefore3Rd = getTransitionSearchDto(
      TransitionSearchListSearchDtoContainerStateEnum.Before3RdCheckpointGo
    )
    transitionService
      .searchForList(undefined, undefined, undefined, transitionSearchDtoBefore2Nd)
      .then((response) => {
        const transitionContent = response.data.content ?? []
        if (transitionContent.length !== 0) {
          if (notification?.id) {
            toast.dismiss(notification.id)
          }
          showDetail(transitionContent[0]?.id)
        } else {
          transitionService
            .searchForList(undefined, undefined, undefined, transitionSearchDtoBefore3Rd)
            .then((response) => {
              const transitionContent = response.data.content ?? []
              if (transitionContent.length !== 0) {
                if (notification?.id) {
                  toast.dismiss(notification.id)
                }
                showDetail(transitionContent[0]?.id)
              }
            })
            .catch()
        }
      })
      .catch()
  }

  const handleGateOutFromKiosk = () => {
    if (notification?.id) {
      toast.dismiss(notification.id)
    }
    dispatch(setShowNotifications(false))
    history.push('/gate-out/single-list', {
      notification: notification,
      driver: notification.driverArrival?.driver,
      carrier: notification.driverArrival?.driver?.carrierShort,
      frontLicensePlate: notification.driverArrival?.driver?.frontLicensePlate,
      rearLicensePlate: notification.driverArrival?.driver?.rearLicensePlate,
    })
  }

  const handleParticularGateOutFromKiosk = () => {
    if (notification?.id) {
      toast.dismiss(notification.id)
    }
    dispatch(setShowNotifications(false))
    history.push('/gate-out/single-container/driver/' + notification.driverArrival?.driver?.id, {
      notification: notification,
      driver: notification.driverArrival?.driver,
      carrier: notification.driverArrival?.driver?.carrierShort,
      frontLicensePlate: notification.driverArrival?.driver?.frontLicensePlate,
      rearLicensePlate: notification.driverArrival?.driver?.rearLicensePlate,
    })
  }

  const getTransitionSearchDto = (containerState: TransitionSearchListSearchDtoContainerStateEnum) => {
    return {
      container: {
        iluCode: notification?.iluCode,
      },
      containerState: containerState,
    }
  }

  const getIluCode = () => {
    switch (notification.notificationType) {
      case NotificationDtoNotificationTypeEnum.GiCheckpoint2WithoutIluCode:
      case NotificationDtoNotificationTypeEnum.GoCheckpoint2WithoutIluCode:
        return ''
      default:
        return notification.iluCode
    }
  }

  const handleNewContainer = () => {
    dispatch(
      setTransition({
        ...transitionDefaults.shippingContainer,
        container: {
          ...transitionDefaults.shippingContainer.container,
          iluCode: getIluCode() ?? '',
        },
      })
    )
    setShowPlannedGateInDialog(false)
    if (notification?.id) {
      toast.dismiss(notification.id)
    }
    dispatch(setShowNotifications(false))
    history.push('/gate-in/container', { transitionMode: TransitionMode.NOTIFICATION, notification })
  }

  const closeNotification = () => {
    notificationService.update(notification.id || -1, {
      ...notification,
      closedAt: new Date().toISOString(),
    })

    if (notification?.id) {
      toast.dismiss(notification.id)
    }
  }

  const onModalClose = () => {
    setShowGateOutModal(false)
  }

  const onModalSave = (transitionDto: TransitionDto) => {
    setShowGateOutModal(false)

    dispatch(setTransition(transitionDefaults.shippingContainer))
    history.push('/gate-out/single-container/' + (transitionDto.id ?? -1), { notification })
  }

  const handleNewGateOut = () => {
    setShowGateOutModal(true)
  }

  const handleNewSemitrailer = () => {
    dispatch(
      setTransition({
        ...transitionDefaults.semitrailer,
        container: {
          ...transitionDefaults.semitrailer.container,
          iluCode: getIluCode() ?? '',
        },
      })
    )
    setShowPlannedGateInDialog(false)
    if (notification?.id) {
      toast.dismiss(notification.id)
    }
    history.push('/gate-in/semitrailer', { transitionMode: TransitionMode.NOTIFICATION, notification })
    dispatch(setShowNotifications(false))
  }

  const handleSemaphore = (semaphoreMode: SemaphoreMode, asparkingCheckpoint: AsparkingCheckpoint) => {
    asparkingSemaphoreControlService.asparkingSemaphoreControl(semaphoreMode, asparkingCheckpoint).then((response) => {
      if (response.status === 200) {
        closeNotification()
      }
    })
  }

  const handleRemove = async () => {
    setIsDeleting(true)
    const notificationDtoRemove = {
      ...notification,
      closedAt: new Date().toISOString(),
    }
    try {
      await notificationService.update(notification.id || -1, notificationDtoRemove)
      setShowRemoveDialog(false)
      refreshList()
      if (notification?.id) {
        toast.dismiss(notification.id)
      }
    } catch (error) {
      console.error("Can't remove notification", error)
    } finally {
      setIsDeleting(false)
    }
  }

  const handleGateControl = (open: boolean) => {
    if (open && notification.gate) {
      asparkingGateControlService.asparkingGateControlOpenGate(notification.gate.asparkingNumber)
    }

    closeNotification()
  }

  const handleEmptyCoparnGateOut = (fromKiosk = false) => {
    if (notification?.id) {
      toast.dismiss(notification.id)
    }
    dispatch(setShowNotifications(false))
    history.push(
      '/gate-out/coparn-empty/' + (fromKiosk ? notification.driverArrival?.driver?.id + '/' : '') + 'create',
      {
        notification: notification,
        driverShort: fromKiosk ? notification.driverArrival?.driver : undefined,
        carrierShort: fromKiosk ? notification.driverArrival?.driver?.carrierShort : undefined,
        frontLicensePlate: fromKiosk ? notification.driverArrival?.driver?.frontLicensePlate : undefined,
        rearLicensePlate: fromKiosk ? notification.driverArrival?.driver?.rearLicensePlate : undefined,
      }
    )
  }

  const gateInCheckpoint2 = () => {
    return (
      <>
        <Button
          className={classes.button}
          size="small"
          color="primary"
          variant="contained"
          onClick={() => setShowPlannedGateInDialog(true)}
        >
          <Typography variant="body2">{t('pages.notifications.buttons.gateIn')}</Typography>
        </Button>
        <Button
          className={classes.button}
          size="small"
          color="primary"
          variant="contained"
          onClick={() => handleSemaphore(SemaphoreMode.RIGHT_DIRECTION, AsparkingCheckpoint.CHECKPOINT_2_IN)}
        >
          <Typography variant="body2">{t('pages.notifications.buttons.parkingLot')}</Typography>
        </Button>
        <Button
          className={classes.button}
          size="small"
          color="primary"
          variant="contained"
          onClick={() => setShowRemoveDialog(true)}
        >
          <Typography variant="body2">{t('pages.notifications.buttons.remove')}</Typography>
        </Button>
        <NotificationRemoveDialog
          showRemoveDialog={showRemoveDialog}
          onClose={() => setShowRemoveDialog(false)}
          onRemove={handleRemove}
          isDisabled={isDeleting}
        />
        <NotificationPlannedGateInDialog
          showGateInDialog={showPlannedGateInDialog}
          onClose={() => setShowPlannedGateInDialog(false)}
          onPlanned={handlePlannedGateIn}
          onNewContainer={handleNewContainer}
          onNewSemitrailer={handleNewSemitrailer}
          getTransitionSearchDto={getTransitionSearchDto}
        />
      </>
    )
  }

  const gateInCheckpoint2WithoutIlu = () => {
    return (
      <>
        <Button
          className={classes.button}
          size="small"
          color="primary"
          variant="contained"
          onClick={() => setShowPlannedGateInDialog(true)}
        >
          <Typography variant="body2">{t('pages.notifications.buttons.gateIn')}</Typography>
        </Button>
        <Button
          className={classes.button}
          size="small"
          color="primary"
          variant="contained"
          onClick={() => handleNewGateOut()}
        >
          <Typography variant="body2">{t('pages.notifications.buttons.gateOut')}</Typography>
        </Button>
        <Button className={classes.button} size="small" color="primary" variant="contained">
          <Typography variant="body2" onClick={() => handleEmptyCoparnGateOut()}>
            {t('pages.notifications.buttons.gateOutEmpty')}
          </Typography>
        </Button>
        <Button
          className={classes.button}
          size="small"
          color="primary"
          variant="contained"
          onClick={() => handleSemaphore(SemaphoreMode.RIGHT_DIRECTION, AsparkingCheckpoint.CHECKPOINT_2_IN)}
        >
          <Typography variant="body2">{t('pages.notifications.buttons.parkingLot')}</Typography>
        </Button>
        <Button
          className={classes.button}
          size="small"
          color="primary"
          variant="contained"
          onClick={() => setShowRemoveDialog(true)}
        >
          <Typography variant="body2">{t('pages.notifications.buttons.remove')}</Typography>
        </Button>
        {showGateOutModal && <GateOutTransitionSelectionModal open onClose={onModalClose} onSave={onModalSave} />}
        <NotificationRemoveDialog
          showRemoveDialog={showRemoveDialog}
          onClose={() => setShowRemoveDialog(false)}
          onRemove={handleRemove}
          isDisabled={isDeleting}
        />
        <NotificationPlannedGateInDialog
          showGateInDialog={showPlannedGateInDialog}
          onClose={() => setShowPlannedGateInDialog(false)}
          onPlanned={handlePlannedGateIn}
          onNewContainer={handleNewContainer}
          onNewSemitrailer={handleNewSemitrailer}
          getTransitionSearchDto={getTransitionSearchDto}
        />
      </>
    )
  }

  switch (notification.notificationType) {
    case NotificationDtoNotificationTypeEnum.GiCheckpoint2:
      return gateInCheckpoint2()
    case NotificationDtoNotificationTypeEnum.GiCheckpoint2WithoutIluCode:
      return gateInCheckpoint2WithoutIlu()
    case NotificationDtoNotificationTypeEnum.FailedGreenPass:
      if (!!notification.iluCode) {
        return gateInCheckpoint2()
      } else {
        return gateInCheckpoint2WithoutIlu()
      }
    case NotificationDtoNotificationTypeEnum.GoCheckpoint2:
    case NotificationDtoNotificationTypeEnum.GateOutCoparn:
      return (
        <>
          <Button
            className={classes.button}
            size="small"
            color="primary"
            variant="contained"
            onClick={() => handleGateOut()}
          >
            <Typography variant="body2">{t('any.buttons.detail')}</Typography>
          </Button>
          <Button
            className={classes.button}
            size="small"
            color="primary"
            variant="contained"
            onClick={() => setShowRemoveDialog(true)}
          >
            <Typography variant="body2">{t('pages.notifications.buttons.remove')}</Typography>
          </Button>
          <NotificationRemoveDialog
            showRemoveDialog={showRemoveDialog}
            onClose={() => setShowRemoveDialog(false)}
            onRemove={handleRemove}
            isDisabled={isDeleting}
          />
        </>
      )
    case NotificationDtoNotificationTypeEnum.GoCheckpoint2WithoutIluCode:
      return (
        <>
          <Button
            className={classes.button}
            size="small"
            color="primary"
            variant="contained"
            onClick={() => handleGateControl(true)}
          >
            <Typography variant="body2">{t('any.buttons.openGate')}</Typography>
          </Button>
          <Button
            className={classes.button}
            size="small"
            color="primary"
            variant="contained"
            onClick={() => setShowRemoveDialog(true)}
          >
            <Typography variant="body2">{t('pages.notifications.buttons.remove')}</Typography>
          </Button>
          <NotificationRemoveDialog
            showRemoveDialog={showRemoveDialog}
            onClose={() => setShowRemoveDialog(false)}
            onRemove={handleRemove}
            isDisabled={isDeleting}
          />
        </>
      )
    case NotificationDtoNotificationTypeEnum.LicensePlateReadError:
    case NotificationDtoNotificationTypeEnum.UnknownVehicle:
      return (
        <>
          <Button
            className={classes.button}
            size="small"
            color="primary"
            variant="contained"
            onClick={() => handleGateControl(true)}
          >
            <Typography variant="body2">{t('any.buttons.openGate')}</Typography>
          </Button>
          <Button
            className={classes.button}
            size="small"
            color="primary"
            variant="contained"
            onClick={() => setShowRemoveDialog(true)}
          >
            <Typography variant="body2">{t('pages.notifications.buttons.remove')}</Typography>
          </Button>
          <NotificationRemoveDialog
            showRemoveDialog={showRemoveDialog}
            onClose={() => setShowRemoveDialog(false)}
            onRemove={handleRemove}
            isDisabled={isDeleting}
          />
        </>
      )
    case NotificationDtoNotificationTypeEnum.IluReadError:
      return (
        <>
          {notification?.gate?.asparkingNumber === 6 && (
            <Button
              className={classes.button}
              size="small"
              color="primary"
              variant="contained"
              onClick={() => setShowPlannedGateInDialog(true)}
            >
              <Typography variant="body2">{t('pages.notifications.buttons.gateIn')}</Typography>
            </Button>
          )}
          <Button
            className={classes.button}
            size="small"
            color="primary"
            variant="contained"
            onClick={() => setShowRemoveDialog(true)}
          >
            <Typography variant="body2">{t('pages.notifications.buttons.remove')}</Typography>
          </Button>
          <NotificationPlannedGateInDialog
            showGateInDialog={showPlannedGateInDialog}
            onClose={() => setShowPlannedGateInDialog(false)}
            onPlanned={handlePlannedGateIn}
            onNewContainer={handleNewContainer}
            onNewSemitrailer={handleNewSemitrailer}
            getTransitionSearchDto={getTransitionSearchDto}
          />
          <NotificationRemoveDialog
            showRemoveDialog={showRemoveDialog}
            onClose={() => setShowRemoveDialog(false)}
            onRemove={handleRemove}
            isDisabled={isDeleting}
          />
        </>
      )
    case NotificationDtoNotificationTypeEnum.M2MTrainCameraRead:
      return (
        <>
          <Button
            className={classes.button}
            size="small"
            color="primary"
            variant="contained"
            onClick={() => setShowCompareDialog(true)}
          >
            <Typography variant="body2">{t('any.buttons.compare')}</Typography>
          </Button>
          <Button
            className={classes.button}
            size="small"
            color="primary"
            variant="contained"
            onClick={() => setShowRemoveDialog(true)}
          >
            <Typography variant="body2">{t('pages.notifications.buttons.remove')}</Typography>
          </Button>
          <TrainM2mCompareDialog
            showCompareDialog={showCompareDialog}
            setShowCompareDialog={setShowCompareDialog}
            m2mTrainId={notification?.m2mTrainId ?? -1}
          />
          <NotificationRemoveDialog
            showRemoveDialog={showRemoveDialog}
            onClose={() => setShowRemoveDialog(false)}
            onRemove={handleRemove}
            isDisabled={isDeleting}
          />
        </>
      )
    case NotificationDtoNotificationTypeEnum.DriverArrivalWithGateIn:
      return (
        <>
          <Button
            className={classes.button}
            size="small"
            color="primary"
            variant="contained"
            onClick={showDetailFromKiosk}
          >
            <Typography variant="body2">{t('pages.notifications.buttons.gateIn')}</Typography>
          </Button>
          <Button
            className={classes.button}
            size="small"
            color="primary"
            variant="outlined"
            onClick={() => setShowRemoveDialog(true)}
          >
            <Typography variant="body2">{t('pages.notifications.buttons.remove')}</Typography>
          </Button>
          <NotificationRemoveDialog
            showRemoveDialog={showRemoveDialog}
            onClose={() => setShowRemoveDialog(false)}
            onRemove={handleRemove}
            isDisabled={isDeleting}
          />
        </>
      )
    case NotificationDtoNotificationTypeEnum.DriverArrivalWithGateOutEmpty:
      return (
        <>
          <Button
            className={classes.button}
            size="small"
            color="primary"
            variant="contained"
            onClick={() => handleEmptyCoparnGateOut(true)}
          >
            <Typography variant="body2">{t('pages.notifications.buttons.gateOutEmpty')}</Typography>
          </Button>
          <Button
            className={classes.button}
            size="small"
            color="primary"
            variant="outlined"
            onClick={() => setShowRemoveDialog(true)}
          >
            <Typography variant="body2">{t('pages.notifications.buttons.remove')}</Typography>
          </Button>
          <NotificationRemoveDialog
            showRemoveDialog={showRemoveDialog}
            onClose={() => setShowRemoveDialog(false)}
            onRemove={handleRemove}
            isDisabled={isDeleting}
          />
        </>
      )
    case NotificationDtoNotificationTypeEnum.DriverArrivalWithGateOutFull:
    case NotificationDtoNotificationTypeEnum.DriverArrivalWithGateOutSemitrailer:
      return (
        <>
          <Button
            className={classes.button}
            size="small"
            color="primary"
            variant="contained"
            onClick={handleParticularGateOutFromKiosk}
          >
            <Typography variant="body2">{t('pages.notifications.buttons.gateOut')}</Typography>
          </Button>
          <Button
            className={classes.button}
            size="small"
            color="primary"
            variant="outlined"
            onClick={() => setShowRemoveDialog(true)}
          >
            <Typography variant="body2">{t('pages.notifications.buttons.remove')}</Typography>
          </Button>
          <NotificationRemoveDialog
            showRemoveDialog={showRemoveDialog}
            onClose={() => setShowRemoveDialog(false)}
            onRemove={handleRemove}
            isDisabled={isDeleting}
          />
        </>
      )
    case NotificationDtoNotificationTypeEnum.DriverArrivalWithGateOutEmptyFailed:
    case NotificationDtoNotificationTypeEnum.DriverArrivalWithGateOutFullFailed:
      return (
        <>
          <Button
            className={classes.button}
            size="small"
            color="primary"
            variant="contained"
            onClick={handleGateOutFromKiosk}
          >
            <Typography variant="body2">{t('pages.notifications.buttons.gateOut')}</Typography>
          </Button>
          <Button
            className={classes.button}
            size="small"
            color="primary"
            variant="contained"
            onClick={() => handleEmptyCoparnGateOut(true)}
          >
            <Typography variant="body2">{t('pages.notifications.buttons.gateOutEmpty')}</Typography>
          </Button>
          <Button
            className={classes.button}
            size="small"
            color="primary"
            variant="outlined"
            onClick={() => setShowRemoveDialog(true)}
          >
            <Typography variant="body2">{t('pages.notifications.buttons.remove')}</Typography>
          </Button>
          <NotificationRemoveDialog
            showRemoveDialog={showRemoveDialog}
            onClose={() => setShowRemoveDialog(false)}
            onRemove={handleRemove}
            isDisabled={isDeleting}
          />
        </>
      )
    case NotificationDtoNotificationTypeEnum.SuccessfulGreenPass:
    case NotificationDtoNotificationTypeEnum.LicensePlateWithFlag:
    case NotificationDtoNotificationTypeEnum.ContainerOverload:
    case NotificationDtoNotificationTypeEnum.VgmWeighted:
      return (
        <>
          <Button
            className={classes.button}
            size="small"
            color="primary"
            variant="contained"
            onClick={() => setShowRemoveDialog(true)}
          >
            <Typography variant="body2">{t('pages.notifications.buttons.remove')}</Typography>
          </Button>
          <NotificationRemoveDialog
            showRemoveDialog={showRemoveDialog}
            onClose={() => setShowRemoveDialog(false)}
            onRemove={handleRemove}
            isDisabled={isDeleting}
          />
        </>
      )
    default:
      return <></>
  }
}
