import React, { FC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { TransitionDto, UserDtoRoleEnum } from '../../../api'
import { createStyles, Divider, Grid, makeStyles } from '@material-ui/core'
import { RepletedBox } from './RepletedBox'
import { AdrRidBox } from './AdrRidBox'
import ConardButton from '../../../components/ConardButton'
import { TransitionDatePicker } from '../../../components/transition/TransitionDatePicker'
import DriverForm from '../../../components/gate-in/DriverForm'
import { yupResolver } from '@hookform/resolvers/yup'
import { ValidationScheme } from './ValidationScheme'
import { FormViewMode } from '../../../enums/FormViewMode'
import { ContainerBox } from '../container/ContainerBox'
import { ContainerHeaderIcon } from '../container/ContainerHeaderIcon'
import { NoteField } from './NoteField'
import { ExtrasField } from './ExtrasField'
import { ReferenceField } from './ReferenceField'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { WeightedBox } from './WeightedBox'
import { useConardAuth } from '../../../hooks/useConardAuth'

const useStyles = makeStyles((theme) =>
  createStyles({
    grid: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    title: {
      paddingTop: '8rem',
      paddingLeft: '10px',
      fontSize: 36,
      fontWeight: 'bold',
      color: theme.palette.primary.main,
      [theme.breakpoints.down('md')]: {
        paddingTop: 0,
        fontSize: 24,
      },
    },
    gridItem: {
      width: '100%',
    },
    gridRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      justifyContent: 'center',
      width: '100%',
      gap: '1rem',
    },
    gridColumn: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      gap: '1rem',
      [theme.breakpoints.up('lg')]: {
        width: '32%',
      },
    },
    divider: {
      margin: '1rem 0',
      width: '100%',
    },
    buttonGridItem: {
      width: 'fit-content',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    submitButton: {
      height: '3rem',
      fontWeight: 600,
      borderRadius: 12,
    },
  })
)

interface TransitionFormProps {
  initialValues: TransitionDto
  onSubmit: (data: TransitionDto) => void
  formMode: FormViewMode
  driverId?: number
  signetPhoto: string
  loadingSignetPhoto: boolean
  handleNewPhoto: (photo: string) => void
  submitButtonText: string
  title?: string
}

export const TransitionForm: FC<TransitionFormProps> = ({
  initialValues,
  onSubmit,
  formMode,
  driverId,
  signetPhoto,
  loadingSignetPhoto,
  handleNewPhoto,
  submitButtonText,
  title,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const { getRole } = useConardAuth()

  const formMethods = useForm<TransitionDto>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(ValidationScheme(formMode, initialValues?.container.containerType)),
  })

  const handleBackButton = () => {
    history.goBack()
  }

  return (
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <Grid container className={classes.grid} spacing={2}>
            <Grid container className={classes.gridRow}>
              {[FormViewMode.EXTERN, FormViewMode.FULL].includes(formMode) && (
                <Grid item className={`${classes.gridColumn} ${classes.title}`}>
                  {title}
                </Grid>
              )}
              <Grid item className={classes.gridColumn}>
                <ContainerHeaderIcon
                  iluCode={initialValues.container.iluCode}
                  containerType={initialValues.container.containerType}
                  formMode={formMode}
                />
              </Grid>
              {[FormViewMode.EXTERN, FormViewMode.FULL].includes(formMode) && (
                <Grid item className={classes.gridColumn} />
              )}
            </Grid>

            <Grid container className={classes.gridRow}>
              <Grid container className={classes.gridColumn}>
                <Grid item className={classes.gridItem}>
                  <ContainerBox container={initialValues.container} formMode={formMode} />
                </Grid>
              </Grid>

              <Grid container className={classes.gridColumn}>
                <Grid item className={classes.gridItem}>
                  <RepletedBox
                    formMode={formMode}
                    signetPhoto={signetPhoto}
                    loadingSignetPhoto={loadingSignetPhoto}
                    handleNewPhoto={handleNewPhoto}
                    initialNhmCodes={initialValues.nhmCodes}
                    containerType={initialValues.container.containerType}
                  />
                </Grid>
                {[FormViewMode.FULL, FormViewMode.EXTERN].includes(formMode) && (
                  <Grid item className={classes.gridItem}>
                    <WeightedBox
                      transitionId={initialValues.id}
                      hasVgm={!!initialValues.vgm}
                      disableSwitch={formMode === FormViewMode.EXTERN}
                    />
                  </Grid>
                )}
                {formMode !== FormViewMode.EXTERN && (
                  <>
                    <Grid item className={classes.gridItem}>
                      <AdrRidBox initialCodes={initialValues.adrRidCodes} />
                    </Grid>
                    <Grid item className={classes.gridItem}>
                      <ReferenceField
                        initialId={initialValues.id}
                        initialContainerType={initialValues.container.containerType}
                        formMode={formMode}
                      />
                    </Grid>
                  </>
                )}
                {formMode === FormViewMode.EXTERN && (
                  <Grid item>
                    <ExtrasField initialExtras={initialValues.extras} />
                  </Grid>
                )}
              </Grid>

              <Grid container className={classes.gridColumn}>
                {formMode === FormViewMode.EXTERN && (
                  <Grid item className={classes.gridItem}>
                    <NoteField />
                  </Grid>
                )}
                <Grid item className={classes.gridItem}>
                  <TransitionDatePicker
                    disabled={formMode !== FormViewMode.EXTERN}
                    dateTime={initialValues.transitionDateTime}
                    control={formMethods.control}
                  />
                </Grid>
                <Grid item className={classes.gridItem}>
                  <DriverForm
                    driverId={driverId}
                    control={formMethods.control}
                    containerType={initialValues.container.containerType}
                    disableDriver={formMode === FormViewMode.DRIVER}
                    disableCarrier={formMode === FormViewMode.DRIVER}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Divider className={classes.divider} />

            <Grid container className={classes.gridRow}>
              {formMode !== FormViewMode.DRIVER && (
                <Grid item className={classes.buttonGridItem}>
                  <ConardButton
                    conardVariant="transparent"
                    type="button"
                    className={classes.submitButton}
                    onClick={handleBackButton}
                  >
                    {t('any.buttons.back')}
                  </ConardButton>
                </Grid>
              )}
              {getRole() !== UserDtoRoleEnum.Manager && (
                <Grid item className={classes.buttonGridItem}>
                  <ConardButton conardVariant="dark" type="submit" className={classes.submitButton}>
                    {submitButtonText}
                  </ConardButton>
                </Grid>
              )}
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  )
}
