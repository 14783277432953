import {
  ContainerDto,
  ContainerDtoContainerTypeEnum,
  ContainerDtoSemitrailerTypeEnum,
  ContainerDtoShippingContainerTypeEnum,
  ContainerSimpleDtoContainerTypeEnum,
  ContainerSimpleDtoSemitrailerTypeEnum,
  ContainerSimpleDtoShippingContainerTypeEnum,
  OperatorTransitionDtoContainerTypeEnum,
  OperatorTransitionDtoSemitrailerTypeEnum,
  OperatorTransitionDtoShippingContainerTypeEnum,
  TransitionDtoDirectionEnum,
  TransitionDtoImportExportEnum,
  TransitionDtoReceiptMethodEnum,
  TransitionSearchListDtoContainerTypeEnum,
  TransitionSearchListDtoSemitrailerTypeEnum,
  TransitionSearchListDtoShippingContainerTypeEnum,
} from '../api'
import transitionService from '../services/TransitionService'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const cleanObject = (obj: any): any => {
  for (const propName in obj) {
    let isNotSelectedList = false
    if (Array.isArray(obj[propName])) {
      if (obj[propName] !== null && typeof obj[propName] === 'object') {
        cleanObject(obj[propName])
        continue
      }
      isNotSelectedList = (obj[propName] as []).every(function (element, index) {
        return element === ['NOT-SELECTED'][index]
      })
    }

    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === '' ||
      obj[propName] === 'NOT-SELECTED' ||
      obj[propName] === -1 ||
      isNotSelectedList
    ) {
      const propertyDescriptor = Object.getOwnPropertyDescriptor(obj, propName)
      if (propertyDescriptor?.configurable) {
        delete obj[propName]
      }
    } else {
      if (typeof obj[propName] === 'object') {
        cleanObject(obj[propName])
      }
    }
  }
  return obj
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const cleanShippingContainerType = (containerTypeEnum: any) => {
  if (containerTypeEnum?.substr(0, 4) === 'ISO_') {
    return containerTypeEnum.substr(4)
  }
  return containerTypeEnum
}

export const setImportExport = async (
  direction: TransitionDtoDirectionEnum,
  receiptMethod: TransitionDtoReceiptMethodEnum,
  repleted: boolean,
  container: ContainerDto
) => {
  if (direction === TransitionDtoDirectionEnum.In) {
    if (receiptMethod === TransitionDtoReceiptMethodEnum.Train && repleted) {
      return TransitionDtoImportExportEnum.Import
    }
    if (receiptMethod === TransitionDtoReceiptMethodEnum.Train && !repleted) {
      return TransitionDtoImportExportEnum.None
    }
    return repleted ? TransitionDtoImportExportEnum.Export : TransitionDtoImportExportEnum.None
  }
  if (receiptMethod === TransitionDtoReceiptMethodEnum.Train) {
    return TransitionDtoImportExportEnum.Export
  }

  if (repleted) {
    if (container.containerType === ContainerDtoContainerTypeEnum.Semitrailer) {
      const response = await transitionService.findLastByIlu(container.iluCode).catch(() => null)
      if (response?.status === 200) {
        return !response.data || response.data.repleted
          ? TransitionDtoImportExportEnum.Import
          : TransitionDtoImportExportEnum.Export
      }
    }
    return TransitionDtoImportExportEnum.Import
  }
  return TransitionDtoImportExportEnum.Export
}

export const convertToTons = (kilograms: number) => {
  const tons = kilograms / 1000
  return Math.round(tons * 10) / 10
}

export interface TransitionSearchData {
  containerType:
    | TransitionSearchListDtoContainerTypeEnum
    | ContainerDtoContainerTypeEnum
    | OperatorTransitionDtoContainerTypeEnum
    | ContainerSimpleDtoContainerTypeEnum
    | undefined
  semitrailerType:
    | TransitionSearchListDtoSemitrailerTypeEnum
    | ContainerDtoSemitrailerTypeEnum
    | OperatorTransitionDtoSemitrailerTypeEnum
    | ContainerSimpleDtoSemitrailerTypeEnum
    | undefined
  shippingContainerType:
    | TransitionSearchListDtoShippingContainerTypeEnum
    | ContainerDtoShippingContainerTypeEnum
    | OperatorTransitionDtoShippingContainerTypeEnum
    | ContainerSimpleDtoShippingContainerTypeEnum
    | undefined
}

export const renderContainerType = (transition: TransitionSearchData | undefined) => {
  return transition?.containerType === TransitionSearchListDtoContainerTypeEnum.Semitrailer
    ? transition.semitrailerType
    : cleanShippingContainerType(transition?.shippingContainerType)
}

export const getDateWithZeroSeconds = (date: string) => {
  const dateTime = new Date(date)
  dateTime.setSeconds(0)

  return dateTime
}

export const formatMinutesToHours = (minutes: number) => {
  const hours = Math.floor(minutes / 60)
  const remainingMinutes = minutes % 60
  return `${hours} h ${remainingMinutes} min`
}
