import React, { ChangeEvent, FC, useState } from 'react'
import { ConardLabel } from '../../../components/transition/DriverFields'
import { useTranslation } from 'react-i18next'
import ConardSelect from '../../../components/ConardSelect'
import {
  ContainerDto,
  ContainerDtoContainerTypeEnum,
  ContainerDtoSemitrailerTypeEnum,
  ContainerDtoShippingContainerTypeEnum,
} from '../../../api'
import { createStyles, makeStyles, MenuItem } from '@material-ui/core'
import { cleanShippingContainerType } from '../../../utils/utils'
import ConardTextField from '../../../components/ConardTextField'
import { Teu } from '../../../enums/Teu'
import { useFormContext } from 'react-hook-form'
import { FormViewMode } from '../../../enums/FormViewMode'
import { KioskLabel } from '../../kiosk/shared/KioskLabel'

const useStyles = makeStyles(
  createStyles({
    teu: {
      display: 'none',
    },
  })
)

interface ContainerTypeFieldProps {
  container: ContainerDto | undefined
  formMode: FormViewMode
  fieldDisabled: <K extends keyof ContainerDto>(field: K) => boolean
}

export const ContainerTypeField: FC<ContainerTypeFieldProps> = ({ container, formMode, fieldDisabled }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { control, errors } = useFormContext()

  const [selectedShippingContainerType, setSelectedShippingContainerType] = useState<
    ContainerDtoShippingContainerTypeEnum | ''
  >(container?.shippingContainerType ?? '')
  const [selectedSemitrailerType, setSelectedSemitrailerType] = useState<ContainerDtoSemitrailerTypeEnum | ''>(
    container?.semitrailerType ?? ''
  )

  const handleShippingTypeChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSelectedShippingContainerType(event.target.value as ContainerDtoShippingContainerTypeEnum)
    control.setValue(
      'container.teu',
      Teu[control.getValues('container.shippingContainerType') as keyof typeof Teu] ?? 1
    )
  }

  const handleSemitrailerType = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSelectedSemitrailerType(event.target.value as ContainerDtoSemitrailerTypeEnum)
    control.setValue('container.teu', Teu[control.getValues('container.semitrailerType') as keyof typeof Teu] ?? 2.4)
  }

  const disabled = fieldDisabled(
    container?.containerType === ContainerDtoContainerTypeEnum.ShippingContainer
      ? 'shippingContainerType'
      : 'semitrailerType'
  )

  return (
    <>
      {formMode === FormViewMode.KIOSK ? (
        <KioskLabel inactive={disabled}>
          {t(
            'form.containerForm.containerType.' +
              (container?.containerType === ContainerDtoContainerTypeEnum.ShippingContainer
                ? 'shippingContainerType'
                : 'semitrailerType')
          )}
        </KioskLabel>
      ) : (
        <ConardLabel inactive={disabled}>
          {t(
            'form.containerForm.containerType.' +
              (container?.containerType === ContainerDtoContainerTypeEnum.ShippingContainer
                ? 'shippingContainerType'
                : 'semitrailerType')
          )}
        </ConardLabel>
      )}

      {container?.containerType === ContainerDtoContainerTypeEnum.ShippingContainer ? (
        <ConardSelect
          roundedSquare
          name="container.shippingContainerType"
          control={control}
          defaultValue=""
          disabled={disabled}
          error={!!errors.container?.shippingContainerType}
          helperText={errors.container?.shippingContainerType?.message ?? ''}
          value={selectedShippingContainerType}
          onChange={(event) => handleShippingTypeChange(event)}
        >
          {Object.values(ContainerDtoShippingContainerTypeEnum).map((type) => (
            <MenuItem key={type} value={type}>
              {cleanShippingContainerType(type)}
            </MenuItem>
          ))}
        </ConardSelect>
      ) : (
        <ConardSelect
          roundedSquare
          name="container.semitrailerType"
          control={control}
          defaultValue=""
          disabled={disabled}
          error={!!errors.container?.semitrailerType}
          helperText={errors.container?.semitrailerType?.message ?? ''}
          value={selectedSemitrailerType}
          onChange={(event) => handleSemitrailerType(event)}
        >
          {Object.values(ContainerDtoSemitrailerTypeEnum).map((type) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </ConardSelect>
      )}

      <div className={classes.teu}>
        <ConardTextField roundedSquare name="container.teu" control={control} defaultValue="" disabled type="number" />
      </div>
    </>
  )
}
