import React, { FC } from 'react'
import { DatePicker } from '@material-ui/pickers'
import { InputAdornment, makeStyles } from '@material-ui/core'
import { Controller } from 'react-hook-form'
import { Control } from 'react-hook-form/dist/types/form'
import { useTranslation } from 'react-i18next'
import { Event } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  datePickerRoot: {
    width: '100%',
    background: theme.palette.secondary.main,
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'rgba(0, 0, 0, 0.23)',
    borderRadius: 12,
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
  },
  datePickerInputInput: {
    width: '100%',
    textAlign: 'center',
  },
  datePickerInputRoot: {
    width: '60%',
    height: '56px',
    margin: 'auto',
  },
}))

interface TransitionDatePickerProps {
  dateTime?: string
  disabled?: boolean
  control: Control
}

export const TransitionDatePicker: FC<TransitionDatePickerProps> = ({ dateTime, disabled, control }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Controller
      id="transitionDateTime"
      name="transitionDateTime"
      control={control}
      defaultValue={dateTime}
      render={() => (
        <DatePicker
          emptyLabel={t('form.transitionForm.dateTime')}
          format="dd.MM.yyyy HH:mm"
          value={dateTime}
          disabled={!!disabled}
          onChange={() => {
            return
          }}
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position="start">
                <Event />
              </InputAdornment>
            ),
            classes: {
              root: classes.datePickerInputRoot,
              input: classes.datePickerInputInput,
            },
          }}
          className={classes.datePickerRoot}
        />
      )}
    />
  )
}
