import { Box, createStyles, makeStyles, Typography } from '@material-ui/core'
import { format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next/'
import { PageTrainViewDto } from '../../../api'
import trainGateInService from '../../../services/TrainGateInService'
import { formatMinutesToHours } from '../../../utils/utils'

const useStyles = makeStyles((theme) =>
  createStyles({
    header: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: '20px',
      fontSize: '20px',
      fontWeight: 750,
    },
    container: {
      maxWidth: '600px',
      margin: 'auto',
    },
    trainWidget: {
      gap: '5px',
      display: 'flex',
      flexDirection: 'column',
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: theme.palette.primary.dark,
      borderRadius: '12px',
      padding: '10px',
      margin: '5px',
      backgroundColor: 'white',
    },
    trainName: {
      fontWeight: 750,
      color: theme.palette.primary.dark,
    },
    trainInfo: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    wrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '5px',
    },
    cargoLabel: {
      fontStyle: 'italic',
      fontSize: '14px',
    },
    smallText: {
      fontSize: '14px',
    },
  })
)

export const DriversTrainView = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [trainData, setTrainData] = useState<PageTrainViewDto | undefined>(undefined)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await trainGateInService.getGateInTrainView(0, 100, undefined)
        setTrainData(response.data)
      } catch (error) {
        console.error('Error fetching train data:', error)
      }
    }

    fetchData()
  }, [])

  return (
    <Box className={classes.container}>
      <Typography className={classes.header} color="primary">
        {t('driverApp.trains.header').toUpperCase()}
      </Typography>
      <div>
        {trainData?.content?.map((train) => (
          <div className={classes.trainWidget} key={train.id}>
            <div className={classes.trainInfo}>
              <Typography className={classes.trainName}>{train.name}</Typography>
              <Typography className={classes.smallText}>{t(`enum.trainState.${train.trainState}`)}</Typography>
            </div>
            {train.companies && (
              <div className={classes.wrapper}>
                <Typography className={classes.smallText}>{t('driverApp.trains.labels.cargo')}</Typography>
                <Typography className={classes.cargoLabel}>
                  {train.companies?.map((company) => company).join(', ')}
                </Typography>
              </div>
            )}
            {train.plannedDateTime && (
              <div className={classes.wrapper}>
                <Typography className={classes.smallText}>{t('driverApp.trains.labels.plannedArrivalDate')}</Typography>
                <Typography className={classes.smallText}>
                  {train.plannedDateTime ? format(new Date(train.plannedDateTime), 'dd.MM.yyyy HH:mm') : ''}
                </Typography>
              </div>
            )}
            <div className={classes.wrapper}>
              <Typography className={classes.smallText}>{t('driverApp.trains.labels.delay')}</Typography>
              <Typography
                style={{
                  color: (train.departureDelayMinutes ?? 0) > 0 ? 'red' : 'green',
                }}
              >
                {train.departureDelayMinutes !== undefined ? formatMinutesToHours(train.departureDelayMinutes) : ''}
              </Typography>
            </div>

            {train.departureDateTime && (
              <div className={classes.wrapper}>
                <Typography className={classes.smallText}>{t('driverApp.trains.labels.deaprtureDateTime')}</Typography>
                <Typography className={classes.smallText}>
                  {train.departureDateTime ? format(new Date(train.departureDateTime), 'dd.MM.yyyy HH:mm') : ''}
                </Typography>
              </div>
            )}
            {train.unloadingDateTime && (
              <div className={classes.wrapper}>
                <Typography className={classes.smallText}>{t('driverApp.trains.labels.unloadingTime')}</Typography>
                <Typography className={classes.smallText}>
                  {train.unloadingDateTime ? format(new Date(train.unloadingDateTime), 'dd.MM.yyyy HH:mm') : ''}
                </Typography>
              </div>
            )}
          </div>
        ))}
      </div>
      <div>{trainData?.content && trainData?.totalElements === 0 && <div>{t('pages.common.table.noData')}</div>}</div>
    </Box>
  )
}
