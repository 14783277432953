import React, { FC, useContext, useEffect } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { createStyles, CssBaseline, makeStyles, Theme, WithWidth, withWidth } from '@material-ui/core'
import GateOutPage from '../pages/gateOut/GateOutPage'
import GateOutSimpleListPage from '../pages/gateOut/GateOutSimpleListPage'
import GateOutSimpleEditPage from '../pages/gateOut/GateOutSimpleEditPage'
import ContainerGateInPage from '../pages/gateIn/ContainerGateInPage'
import GateInPage from '../pages/gateIn/GateInPage'
import SemitrailerGateInPage from '../pages/gateIn/SemitrailerGateInPage'
import InvoicePage from '../pages/expenses/invoice/InvoicePage'
import PriceListFormPage from '../pages/expenses/priceList/PriceListFormPage'
import PriceListPage from '../pages/expenses/priceList/PriceListPage'
import UserListPage from '../pages/user/UserListPage'
import CompanyListPage from '../pages/customer/company/CompanyListPage'
import CompanyEditPage from '../pages/customer/company/CompanyEditPage'
import CompanyCreatePage from '../pages/customer/company/CompanyCreatePage'
import PrivateRoute from './PrivateRoute'
import AuthorizedRoute from './AuthorizedRoute'
import { UserDtoRoleEnum } from '../api'
import UnauthorizedPage from '../pages/unauthorized/UnauthorizedPage'
import UserUpdatePage from '../pages/user/UserUpdatePage'
import UserCreatePage from '../pages/user/UserCreatePage'
import TransitionSearchListPage from '../pages/search/TransitionSearchListPage'
import DemoPage from '../pages/demo/DemoPage'
import TransitionSearchDetailPage from '../pages/search/TransitionSearchDetailPage'
import TransitionSuccessPage from '../pages/success/transition-success/TransitionSuccessPage'
import TrainGateInPage from '../pages/gateIn/TrainGateInPage'
import TrainSuccessPage from '../pages/success/TrainSuccessPage'
import TrainSearchListPage from '../pages/search/TrainSearchListPage'
import TrainSearchDetailPage from '../pages/search/TrainSearchDetailPage'
import AuditListPage from '../pages/audit/AuditListPage'
import GateOutTrainContentSelectPage from '../pages/gateOut/GateOutTrainContentSelectPage'
import GateOutTrainPage from '../pages/gateOut/GateOutTrainPage'
import GateOutTrainDetailPage from '../pages/gateOut/GateOutTrainDetailPage'
import DamageLogCreatePage from '../pages/damage-log/DamageLogCreatePage'
import TrainContainerGateInPage from '../pages/gateIn/TrainContainerGateInPage'
import TrainSemitrailerGateInPage from '../pages/gateIn/TrainSemitrailerGateInPage'
import HomePage from './HomePage'
import ShipownerCreatePage from '../pages/customer/shipowner/ShipownerCreatePage'
import ShipownerEditPage from '../pages/customer/shipowner/ShipownerEditPage'
import ShipownerListPage from '../pages/customer/shipowner/ShipownerListPage'
import ProcessedFileSearchListPage from '../pages/search/ProcessedFilesSearchListPage'
import GateInTransitionPendingListPage from '../pages/search/GateInTransitionPendingListPage'
import { GateOutCoparnListPage } from '../pages/gateOut/GateOutCoparnListPage'
import { GateOutCoparnDetailPage } from '../pages/gateOut/GateOutCoparnDetailPage'
import TicketUpdatePage from '../pages/ticket/TicketUpdatePage'
import TicketListPage from '../pages/ticket/TicketListPage'
import GateControlPage from '../pages/entry-and-parking/GateControlPage'
import NotificationList from '../components/notifications/NotificationList'
import EntryAndParkingDashboard from '../pages/entry-and-parking/EntryAndParkingDashboard'
import NotificationToastContainer from '../components/notifications/NotificationToastContainer'
import TrainExportConfigurationCreatePage from '../pages/export-configuration/train-export-configuration/TrainExportConfigurationCreatePage'
import TrainExportConfigurationListPage from '../pages/export-configuration/train-export-configuration/TrainExportConfigurationListPage'
import TrainExportConfigurationUpdatePage from '../pages/export-configuration/train-export-configuration/TrainExportConfigurationUpdatePage'
import OperatorListPage from '../pages/operator/OperatorListPage'
import MainMenuToolbar from './menu/MainMenuToolbar'
import TrainM2mListPage from '../pages/train/TrainM2mListPage'
import TrainM2mComparePage from '../pages/train/TrainM2mComparePage'
import GateOutCoparnEmptyCreatePage from '../pages/gateOut/GateOutCoparnEmptyCreatePage'
import CoparnSuccessPage from '../pages/success/CoparnSuccessPage'
import OperatorPositioningListPage from '../pages/operator/OperatorPositioningListPage'
import GateOutCoparnEmptyDeletePage from '../pages/gateOut/GateOutCoparnEmptyDeletePage'
import CoparnEmptyDeleteSuccessPage from '../pages/success/CoparnEmptyDeleteSuccessPage'
import userService from '../services/UserService'
import { useDispatch } from 'react-redux'
import { setNotificationsDisabled } from '../redux/notification/notificationSlice'
import CustomsListPage from '../pages/customs/CustomsListPage'
import CustomsHistoryListPage from '../pages/customs/CustomsHistoryListPage'
import ExportConfigurationDashboard from '../pages/export-configuration/ExportConfigurationDashboard'
import CdCargoExportConfigurationUpdatePage from '../pages/export-configuration/cd-cargo-export-configuration/CdCargoExportConfigurationUpdatePage'
import CdCargoExportConfigurationListPage from '../pages/export-configuration/cd-cargo-export-configuration/CdCargoExportConfigurationListPage'
import CdCargoExportConfigurationCreatePage from '../pages/export-configuration/cd-cargo-export-configuration/CdCargoExportConfigurationCreatePage'
import CarrierListPage from '../pages/carrierManagement/CarrierListPage'
import CarrierCreatePage from '../pages/carrierManagement/CarrierCreatePage'
import CarrierUpdatePage from '../pages/carrierManagement/CarrierUpdatePage'
import LicensePlateListPage from '../pages/license-plate/LicensePlateListPage'
import LicensePlateCreatePage from '../pages/license-plate/LicensePlateCreatePage'
import LicensePlateUpdatePage from '../pages/license-plate/LicensePlateUpdatePage'
import CardCreatePage from '../pages/card/CardCreatePage'
import CardUpdatePage from '../pages/card/CardUpdatePage'
import CardListPage from '../pages/card/CardListPage'
import CardHistoryPage from '../pages/card/CardHistoryPage'
import ShortTermPassageListPage from '../pages/shortTermPassage/ShortTermPassageListPage'
import ShortTermPassageCreatePage from '../pages/shortTermPassage/ShortTermPassageCreatePage'
import ShortTermPassageUpdatePage from '../pages/shortTermPassage/ShortTermPassageUpdatePage'
import TruckMonitoringPage from '../pages/truck-monitoring/TruckMonitoringPage'
import TruckMonitoringLogSearchListPage from '../pages/truck-monitoring/TruckMonitoringLogSearchListPage'
import GateInTrainPrepareList from '../components/gate-in/GateInTrainPrepareList'
import { ConfigContext } from '../context/ConfigContext'
import { useConardAuth } from '../hooks/useConardAuth'
import ContainerStoreForm from '../pages/gateIn/storeForm/ContainerStoreForm'
import TrainStoreForm from '../pages/gateIn/storeForm/TrainStoreForm'
import CustomerDashboard from '../pages/customer/CustomerDashboard'
import ParentCompanyListPage from '../pages/customer/company/ParentCompanyListPage'
import ParentCompanyCreatePage from '../pages/customer/company/ParentCompanyCreatePage'
import TerminalLayout from '../pages/terminal-layout/TerminalLayout'
import TrainDashboard from '../pages/train/TrainDashboard'
import { DriverArrivalPage } from '../pages/drivers/arrival-page/DriverArrivalPage'
import { DriverGateOutPage } from '../pages/drivers/DriverGateOutPage'
import { CheckIluPage } from '../pages/drivers/CheckIluPage'
import { DriverGateInPage } from '../pages/drivers/DriverGateInPage'
import { CarrierMainPage } from '../pages/carrier/main-page/CarrierMainPage'
import { CreateDriver } from '../pages/carrier/drivers-page/CreateDriver'
import { CarrierProvider } from '../context/CarrierContext'
import { DriversPage } from '../pages/carrier/drivers-page/DriversPage'
import { CreateDriverByManager } from '../pages/carrierManagement/CreateDriverByManager'
import { DriversPageForManager } from '../pages/carrierManagement/DriversPageForManager'
import { KioskForm } from '../pages/kiosk/KioskForm'
import { KioskHomePage } from '../pages/kiosk/KioskHomePage'
import TrainCreateGateInPage from '../pages/gateIn/TrainCreateGateInPage'
import TrainCreateGateOutPage from '../pages/gateOut/TrainCreateGateOutPage'
import TrainTransitionUpdatePage from '../pages/gateIn/TrainTransitionUpdatePage'
import { ExpensesDashboard } from '../pages/expenses/ExpensesDashboard'
import { AdditionalServicesPage } from '../pages/expenses/additional-services/AdditionalServicesPage'
import { AdditionalServicesItem } from '../pages/expenses/additional-services/AdditionalServicesItem'
import { GateOutCoparnCreatePage } from '../pages/gateOut/GateOutCoparnCreatePage'
import { PlannedGateOutPage } from '../pages/gateOut/GateOutArcesePage'
import { CarrierSearchListPage } from '../pages/carrier/search-page/CarrierSearchListPage'
import { GateInTrainView } from '../components/gate-in/GateInTrainView'
import { DriversTrainView } from '../pages/drivers/trains/DriversTrainView'
import { WeighForm } from '../pages/vgm/WeighForm'
import { ExternTransitionForm } from '../pages/extern/ExternTransitionForm'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(6),
      [theme.breakpoints.up('sm')]: {
        marginTop: '1vh',
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: '3vh',
        padding: '1.5rem',
      },
    },
    responsiveDebugPanel: {
      width: '100%',
      textAlign: 'center',
      backgroundColor: 'lightcoral',
    },
  })
)

interface BasePageProps extends WithWidth {}

const BasePage: FC<BasePageProps> = (props) => {
  const classes = useStyles()
  const auth = useConardAuth()
  const { entryAndParking, gateInTrainViewMosnov, vgm } = useContext(ConfigContext)
  const dispatch = useDispatch()
  const managerAndDispatchers = [
    UserDtoRoleEnum.Manager,
    UserDtoRoleEnum.Dispatcher,
    UserDtoRoleEnum.DispatcherRoad,
    UserDtoRoleEnum.DispatcherTrain,
  ]

  useEffect(() => {
    userService.userFindById(auth.getUserId() ?? -1).then((response) => {
      if (response.data) {
        dispatch(setNotificationsDisabled(response.data.notificationsDisabled))
      }
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <BrowserRouter>
      <div className={classes.root}>
        <CssBaseline />

        <MainMenuToolbar />

        <main className={classes.content}>
          {/* local development responsive helper */}
          {process.env.REACT_APP_ENVIRONMENT === 'development' && (
            <div className={classes.responsiveDebugPanel}>{props.width}</div>
          )}

          <Switch>
            <AuthorizedRoute
              roles={[
                UserDtoRoleEnum.Dispatcher,
                UserDtoRoleEnum.Operator,
                UserDtoRoleEnum.DispatcherRoad,
                UserDtoRoleEnum.DispatcherTrain,
              ]}
              path="/gate-in/transition/store/:id"
              component={ContainerStoreForm}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Dispatcher, UserDtoRoleEnum.DispatcherTrain]}
              path="/gate-in/train/:id/store"
              component={TrainStoreForm}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Operator, UserDtoRoleEnum.Dispatcher]}
              path="/vgm/weigh"
              component={WeighForm}
            />
            <PrivateRoute path="/success/transition/:id/:type?" component={TransitionSuccessPage} />
            <PrivateRoute path="/success/train/:id/:type?" component={TrainSuccessPage} />
            <PrivateRoute path="/success/coparn" component={CoparnSuccessPage} />
            <PrivateRoute path="/success/coparn-empty/delete" component={CoparnEmptyDeleteSuccessPage} />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Dispatcher, UserDtoRoleEnum.DispatcherRoad]}
              path="/gate-in/container"
              component={ContainerGateInPage}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Dispatcher, UserDtoRoleEnum.DispatcherRoad]}
              path="/gate-in/semitrailer"
              component={SemitrailerGateInPage}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Dispatcher, UserDtoRoleEnum.DispatcherTrain]}
              path="/train/transition/:id"
              component={TrainTransitionUpdatePage}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Dispatcher, UserDtoRoleEnum.DispatcherTrain]}
              path="/gate-in/train/container"
              component={TrainContainerGateInPage}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Dispatcher, UserDtoRoleEnum.DispatcherTrain]}
              path="/gate-in/train/semitrailer"
              component={TrainSemitrailerGateInPage}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Dispatcher, UserDtoRoleEnum.DispatcherTrain]}
              path="/gate-in/train/create"
              exact
              component={TrainCreateGateInPage}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Dispatcher, UserDtoRoleEnum.DispatcherTrain]}
              path="/gate-in/train/:id"
              component={TrainGateInPage}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Dispatcher, UserDtoRoleEnum.DispatcherTrain]}
              path="/gate-in/train"
              component={gateInTrainViewMosnov ? GateInTrainView : GateInTrainPrepareList}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Dispatcher, UserDtoRoleEnum.DispatcherRoad]}
              path="/gate-in/pending"
              component={GateInTransitionPendingListPage}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Dispatcher, UserDtoRoleEnum.DispatcherRoad]}
              path="/gate-in"
              component={GateInPage}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Dispatcher, UserDtoRoleEnum.DispatcherRoad]}
              path="/gate-out/single-list"
              component={GateOutSimpleListPage}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Dispatcher, UserDtoRoleEnum.DispatcherRoad]}
              path="/gate-out/single-container/driver/:driverId"
              exact
              component={GateOutSimpleEditPage}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Dispatcher, UserDtoRoleEnum.DispatcherRoad]}
              path="/gate-out/single-container/:id"
              exact
              component={GateOutSimpleEditPage}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Dispatcher, UserDtoRoleEnum.DispatcherTrain]}
              path="/gate-out/train/:id/content"
              component={GateOutTrainContentSelectPage}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Dispatcher, UserDtoRoleEnum.DispatcherTrain]}
              path="/gate-out/train/create"
              component={TrainCreateGateOutPage}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Dispatcher, UserDtoRoleEnum.DispatcherTrain]}
              path="/gate-out/train/:id"
              component={GateOutTrainDetailPage}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Dispatcher, UserDtoRoleEnum.DispatcherTrain]}
              path="/gate-out/train"
              component={GateOutTrainPage}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Dispatcher, UserDtoRoleEnum.DispatcherRoad, UserDtoRoleEnum.DispatcherTrain]}
              exact
              path="/gate-out/coparn/create"
              component={GateOutCoparnCreatePage}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Dispatcher, UserDtoRoleEnum.DispatcherRoad, UserDtoRoleEnum.DispatcherTrain]}
              exact
              path="/gate-out/coparn"
              component={GateOutCoparnListPage}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Dispatcher, UserDtoRoleEnum.DispatcherRoad, UserDtoRoleEnum.DispatcherTrain]}
              exact
              path="/gate-out/coparn/:id"
              component={GateOutCoparnDetailPage}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Dispatcher, UserDtoRoleEnum.DispatcherRoad]}
              path="/gate-out/coparn-empty/:driverId/create"
              component={GateOutCoparnEmptyCreatePage}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Dispatcher, UserDtoRoleEnum.DispatcherRoad]}
              path="/gate-out/coparn-empty/create"
              component={GateOutCoparnEmptyCreatePage}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Dispatcher, UserDtoRoleEnum.DispatcherRoad]}
              path="/gate-out/coparn-empty/delete"
              component={GateOutCoparnEmptyDeletePage}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Dispatcher, UserDtoRoleEnum.DispatcherRoad, UserDtoRoleEnum.DispatcherTrain]}
              path="/gate-out/planned"
              component={PlannedGateOutPage}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Dispatcher, UserDtoRoleEnum.DispatcherRoad]}
              path="/gate-out"
              component={GateOutPage}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Dispatcher, UserDtoRoleEnum.Manager]}
              path="/extern"
              enabled={vgm}
              component={ExternTransitionForm}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Dispatcher, UserDtoRoleEnum.DispatcherRoad, UserDtoRoleEnum.DispatcherTrain]}
              path="/damage-log/new"
              component={DamageLogCreatePage}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Manager]}
              path="/customer/parent-company/create"
              component={ParentCompanyCreatePage}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Manager]}
              path="/customer/parent-company/update/:id"
              component={CompanyEditPage}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Manager]}
              path="/customer/parent-company"
              component={ParentCompanyListPage}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Manager]}
              path="/customer/company/create"
              component={CompanyCreatePage}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Manager]}
              path="/customer/company/update/:id"
              component={CompanyEditPage}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Manager]}
              path="/customer/company"
              exact
              component={CompanyListPage}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Manager]}
              path="/customer/shipowner/create"
              component={ShipownerCreatePage}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Manager]}
              path="/customer/shipowner/update/:id"
              component={ShipownerEditPage}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Manager]}
              path="/customer/shipowner"
              exact
              component={ShipownerListPage}
            />
            <AuthorizedRoute roles={[UserDtoRoleEnum.Manager]} path="/customer" component={CustomerDashboard} />
            <AuthorizedRoute roles={[UserDtoRoleEnum.Manager]} path="/user/create" component={UserCreatePage} />
            <AuthorizedRoute roles={[UserDtoRoleEnum.Manager]} path="/user/update/:id" component={UserUpdatePage} />
            <AuthorizedRoute roles={[UserDtoRoleEnum.Manager]} path="/user" exact component={UserListPage} />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Manager]}
              path="/export-config/train/create"
              component={TrainExportConfigurationCreatePage}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Manager]}
              path="/export-config/train/update/:id"
              component={TrainExportConfigurationUpdatePage}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Manager]}
              path="/export-config/train"
              component={TrainExportConfigurationListPage}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Manager]}
              path="/export-config/cd-cargo/create"
              component={CdCargoExportConfigurationCreatePage}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Manager]}
              path="/export-config/cd-cargo/update/:id"
              component={CdCargoExportConfigurationUpdatePage}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Manager]}
              path="/export-config/cd-cargo"
              component={CdCargoExportConfigurationListPage}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Manager]}
              path="/export-config"
              component={ExportConfigurationDashboard}
            />
            <AuthorizedRoute
              roles={managerAndDispatchers}
              path="/search/transition"
              exact
              component={TransitionSearchListPage}
            />
            <AuthorizedRoute
              roles={[...managerAndDispatchers, UserDtoRoleEnum.Carrier]}
              path="/search/transition/detail/:id"
              component={TransitionSearchDetailPage}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Manager, UserDtoRoleEnum.Dispatcher, UserDtoRoleEnum.DispatcherTrain]}
              path="/train"
              exact
              component={TrainDashboard}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Manager, UserDtoRoleEnum.Dispatcher, UserDtoRoleEnum.DispatcherTrain]}
              path="/search/train"
              exact
              component={TrainSearchListPage}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Manager, UserDtoRoleEnum.Dispatcher, UserDtoRoleEnum.DispatcherTrain]}
              path="/search/train/detail/:id"
              component={TrainSearchDetailPage}
            />
            <AuthorizedRoute
              roles={managerAndDispatchers}
              path="/search/processed-files"
              exact
              component={ProcessedFileSearchListPage}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Manager]}
              path="/expenses/price-list/update/:id"
              component={PriceListFormPage}
            />
            <AuthorizedRoute roles={[UserDtoRoleEnum.Manager]} path="/history" exact component={AuditListPage} />
            <AuthorizedRoute roles={[UserDtoRoleEnum.Manager]} path="/expenses/price-list" component={PriceListPage} />
            <AuthorizedRoute roles={[UserDtoRoleEnum.Manager]} path="/expenses/invoice" component={InvoicePage} />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Manager]}
              path="/expenses/additional-services/update/:id"
              component={AdditionalServicesItem}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Manager]}
              path="/expenses/additional-services"
              component={AdditionalServicesPage}
            />
            <AuthorizedRoute roles={[UserDtoRoleEnum.Manager]} path="/expenses" component={ExpensesDashboard} />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Manager]}
              path="/entry-and-parking/carrier/create"
              component={CarrierCreatePage}
              enabled={entryAndParking}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Manager]}
              path="/entry-and-parking/carrier/update/:id"
              component={CarrierUpdatePage}
              enabled={entryAndParking}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Manager]}
              path="/entry-and-parking/carrier/:id/license-plate/create"
              component={LicensePlateCreatePage}
              enabled={entryAndParking}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Manager]}
              path="/entry-and-parking/carrier/:id/license-plate/update/:licensePlateId"
              component={LicensePlateUpdatePage}
              enabled={entryAndParking}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Manager]}
              path="/entry-and-parking/carrier/:id/license-plate"
              component={LicensePlateListPage}
              enabled={entryAndParking}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Manager]}
              path="/entry-and-parking/carrier/:id/card/create"
              component={CardCreatePage}
              enabled={entryAndParking}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Manager]}
              path="/entry-and-parking/carrier/:id/card/update/:cardId"
              component={CardUpdatePage}
              enabled={entryAndParking}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Manager]}
              path="/entry-and-parking/carrier/:id/card/history/:cardId"
              component={CardHistoryPage}
              enabled={entryAndParking}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Manager]}
              path="/entry-and-parking/carrier/:id/driver/update/:driverId"
              exact
              component={CreateDriverByManager}
              enabled={entryAndParking}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Manager]}
              path="/entry-and-parking/carrier/:id/driver/create"
              exact
              component={CreateDriverByManager}
              enabled={entryAndParking}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Manager]}
              path="/entry-and-parking/carrier/:id/driver"
              component={DriversPageForManager}
              enabled={entryAndParking}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Manager]}
              path="/entry-and-parking/carrier/:id/card"
              component={CardListPage}
              enabled={entryAndParking}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Manager]}
              exact
              path="/entry-and-parking/carrier"
              component={CarrierListPage}
              enabled={entryAndParking}
            />
            <AuthorizedRoute
              roles={managerAndDispatchers}
              path="/entry-and-parking/short-term-passage/create"
              component={ShortTermPassageCreatePage}
              enabled={entryAndParking}
            />
            <AuthorizedRoute
              roles={managerAndDispatchers}
              path="/entry-and-parking/short-term-passage/update/:id"
              component={ShortTermPassageUpdatePage}
              enabled={entryAndParking}
            />
            <AuthorizedRoute
              roles={managerAndDispatchers}
              exact
              path="/entry-and-parking/short-term-passage"
              component={ShortTermPassageListPage}
              enabled={entryAndParking}
            />
            <AuthorizedRoute
              roles={managerAndDispatchers}
              path="/entry-and-parking/ticket/update/:id"
              component={TicketUpdatePage}
              enabled={entryAndParking}
            />
            <AuthorizedRoute
              roles={managerAndDispatchers}
              path="/entry-and-parking/ticket"
              exact
              component={TicketListPage}
              enabled={entryAndParking}
            />
            <AuthorizedRoute
              roles={managerAndDispatchers}
              path="/entry-and-parking/gate-control"
              exact
              component={GateControlPage}
              enabled={entryAndParking}
            />
            <AuthorizedRoute
              roles={managerAndDispatchers}
              path="/entry-and-parking/monitoring"
              exact
              component={TruckMonitoringPage}
              enabled={entryAndParking}
            />
            <AuthorizedRoute
              roles={managerAndDispatchers}
              path="/entry-and-parking/monitoring/history"
              exact
              component={TruckMonitoringLogSearchListPage}
              enabled={entryAndParking}
            />
            <AuthorizedRoute
              roles={managerAndDispatchers}
              exact
              path="/entry-and-parking"
              component={EntryAndParkingDashboard}
              enabled={entryAndParking}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Manager, UserDtoRoleEnum.Dispatcher, UserDtoRoleEnum.DispatcherTrain]}
              path="/train/m2m"
              exact
              component={TrainM2mListPage}
              enabled={entryAndParking}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Manager, UserDtoRoleEnum.Dispatcher, UserDtoRoleEnum.DispatcherTrain]}
              path="/train/m2m/compare"
              component={TrainM2mComparePage}
              enabled={entryAndParking}
            />
            <AuthorizedRoute roles={[UserDtoRoleEnum.Operator]} path="/operator" exact component={OperatorListPage} />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Driver, UserDtoRoleEnum.Carrier]}
              path="/driver-arrival/gate-in/:id"
              exact
              component={DriverGateInPage}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Driver, UserDtoRoleEnum.Carrier]}
              path="/driver-arrival/gate-out/:id"
              exact
              component={DriverGateOutPage}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Driver, UserDtoRoleEnum.Carrier]}
              path="/driver-arrival/check-ilu/:id"
              exact
              component={CheckIluPage}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Driver, UserDtoRoleEnum.Carrier]}
              path="/driver-arrival"
              exact
              component={DriverArrivalPage}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Operator]}
              path="/operator/positioning"
              exact
              component={OperatorPositioningListPage}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.CustomsOfficer]}
              path="/customs"
              exact
              component={CustomsListPage}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.CustomsOfficer]}
              path="/customs/history"
              exact
              component={CustomsHistoryListPage}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Dispatcher]}
              path="/terminal-layout"
              exact
              component={TerminalLayout}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Carrier]}
              path="/carrier/search"
              component={CarrierSearchListPage}
            />
            <AuthorizedRoute
              roles={[UserDtoRoleEnum.Carrier, UserDtoRoleEnum.Driver]}
              path="/trains"
              exact
              component={() => {
                const role = auth.getRole()
                if (role === UserDtoRoleEnum.Carrier) {
                  return <GateInTrainView />
                } else if (role === UserDtoRoleEnum.Driver) {
                  return <DriversTrainView />
                } else {
                  return <UnauthorizedPage />
                }
              }}
            />
            <AuthorizedRoute roles={[UserDtoRoleEnum.Carrier]} path="/carrier" exact component={CarrierMainPage} />
            <AuthorizedRoute roles={[UserDtoRoleEnum.Carrier]} path="/driver" exact>
              <CarrierProvider>
                <DriversPage />
              </CarrierProvider>
            </AuthorizedRoute>
            <AuthorizedRoute roles={[UserDtoRoleEnum.Carrier]} path="/driver/create" exact>
              <CarrierProvider>
                <CreateDriver />
              </CarrierProvider>
            </AuthorizedRoute>
            <AuthorizedRoute roles={[UserDtoRoleEnum.Carrier]} path="/driver/update/:driverId" exact>
              <CarrierProvider>
                <CreateDriver />
              </CarrierProvider>
            </AuthorizedRoute>
            <AuthorizedRoute roles={[UserDtoRoleEnum.Kiosk]} path="/kiosk/form/:id" exact component={KioskForm} />
            <AuthorizedRoute roles={[UserDtoRoleEnum.Kiosk]} path="/kiosk" exact component={KioskHomePage} />
            <Route path="/unauthorized" component={UnauthorizedPage} />
            <Route path="/demo" component={DemoPage} />
            <PrivateRoute path="/" component={HomePage} />
          </Switch>

          {new Set([UserDtoRoleEnum.Dispatcher, UserDtoRoleEnum.DispatcherRoad, UserDtoRoleEnum.DispatcherTrain]).has(
            auth.getRole()
          ) && (
            <>
              <NotificationList />
              <NotificationToastContainer />
            </>
          )}
        </main>
      </div>
    </BrowserRouter>
  )
}

export default withWidth()(BasePage)
