import React, { FC, useContext, useEffect, useState } from 'react'
import { ConardAccordion, ConardAccordionSummary } from '../../../components/transition/ConardAccordion'
import { useFormContext, useWatch } from 'react-hook-form'
import { CircularProgress, Grid, makeStyles } from '@material-ui/core'
import { RHFSwitch } from '../../../components/shared/rhf/RHFSwitch'
import { useTranslation } from 'react-i18next'
import { AccordionDetails, circularProgressClasses } from '@mui/material'
import { ConardLabel } from '../../../components/transition/DriverFields'
import { ProcessedFileDto } from '../../../api'
import processedFileService from '../../../services/ProcessedFileService'
import ConardButton from '../../../components/ConardButton'
import vgmService from '../../../services/VgmService'
import { format } from 'date-fns'
import { ConfigContext } from '../../../context/ConfigContext'

const useStyles = makeStyles((theme) => ({
  labelRepleted: {
    color: theme.palette.primary.main,
  },
  labelEmpty: {
    color: 'gray',
  },
  gridRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '10px',
  },
  gridButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    gap: '0.5rem',
  },
  vgmText: {
    marginLeft: '8px',
  },
  downloadButton: {
    borderRadius: 12,
  },
  circularProgress: {
    [`& .${circularProgressClasses.circle}`]: {
      strokeLinecap: 'round',
      color: 'lightgray',
    },
  },
}))

interface WeightedBoxProps {
  transitionId: number | undefined
  hasVgm: boolean
  disableSwitch: boolean
}

export const WeightedBox: FC<WeightedBoxProps> = ({ transitionId, hasVgm, disableSwitch }) => {
  const classes = useStyles()
  const { vgm } = useContext(ConfigContext)
  const { t } = useTranslation()
  const { control } = useFormContext()
  const isWeighted = useWatch({
    control: control,
    name: 'weighted',
  })

  const [processedFile, setProcessedFile] = useState<ProcessedFileDto | undefined>(undefined)
  const [loaded, setLoaded] = useState(false)

  const loadProcessedFiles = () => {
    if (transitionId) {
      processedFileService
        .findLatestVgmProtocol(transitionId)
        .then((response) => {
          setProcessedFile(response.data)
        })
        .finally(() => setLoaded(true))
    } else {
      setLoaded(true)
    }
  }

  useEffect(() => {
    loadProcessedFiles()
  }, [transitionId]) // eslint-disable-line react-hooks/exhaustive-deps

  const generateVgm = () => {
    if (transitionId) {
      vgmService.generateVgm(transitionId).then((response) => {
        if (response.data) {
          const url = window.URL.createObjectURL(response.data)
          const a = document.createElement('a')
          a.href = url
          a.download = `VGM_${transitionId}.pdf`
          a.click()

          window.URL.revokeObjectURL(url)
          setLoaded(false)
          loadProcessedFiles()
        }
      })
    }
  }

  const downloadVgm = () => {
    if (processedFile?.contentId)
      processedFileService.download(processedFile.contentId).then((response) => {
        const url = window.URL.createObjectURL(response.data)
        const a = document.createElement('a')
        a.href = url
        a.download = processedFile.fileName ?? ''
        a.click()

        window.URL.revokeObjectURL(url)
      })
  }

  const getVgmText = () => {
    if (!transitionId) {
      return t('form.transitionForm.vgm.transitionNotSaved')
    }
    if (loaded) {
      if (processedFile) {
        return `${t('form.transitionForm.vgm.created')}: ${format(
          new Date(processedFile.processed ?? ''),
          'd.M.yyyy HH:mm'
        )}`
      }
      if (!hasVgm) {
        return `${t('form.transitionForm.vgm.transitionNotWeighed')}`
      }
    }
    return t('form.transitionForm.vgm.notCreated')
  }

  return (
    <ConardAccordion expanded={!!isWeighted}>
      <ConardAccordionSummary>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item className={isWeighted ? classes.labelRepleted : classes.labelEmpty}>
            {t(`form.transitionForm.weighted.${isWeighted}`)}
          </Grid>
          <Grid item>
            <RHFSwitch label="" name="weighted" disabled={disableSwitch} />
          </Grid>
        </Grid>
      </ConardAccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          {vgm && (
            <Grid container className={classes.gridRow}>
              <Grid item xs={12} lg={7}>
                <ConardLabel>{t('form.transitionForm.vgm.label')}</ConardLabel>
                <span className={classes.vgmText}>{getVgmText()}</span>
              </Grid>
              <Grid container xs={12} lg={5} className={classes.gridButtons}>
                {loaded && transitionId && hasVgm && (
                  <>
                    {processedFile && (
                      <Grid item xs={12} xl={5}>
                        <ConardButton
                          conardVariant="transparent"
                          className={classes.downloadButton}
                          onClick={downloadVgm}
                        >
                          {t('any.buttons.download')}
                        </ConardButton>
                      </Grid>
                    )}
                    <Grid item xs={12} xl={6}>
                      <ConardButton
                        conardVariant="transparent"
                        className={classes.downloadButton}
                        onClick={generateVgm}
                      >
                        {t('any.buttons.generate')}
                      </ConardButton>
                    </Grid>
                  </>
                )}
                {!loaded && <CircularProgress size={24} thickness={3} className={classes.circularProgress} />}
              </Grid>
            </Grid>
          )}
        </Grid>
      </AccordionDetails>
    </ConardAccordion>
  )
}
