import {
  PageTrainViewDto,
  TrainDto,
  TrainGateInApi,
  TransitionDto,
  TransitionSearchListDtoQualityTypeEnum,
  TransitionStoreDto,
} from '../api'
import myAxios from '../config/axios/axios'
import { AxiosPromise } from 'axios'
import { cleanObject } from '../utils/utils'

let api: TrainGateInApi

export const setTrainGateInApiConfiguration = () => {
  api = new TrainGateInApi(undefined, process.env.REACT_APP_BE_REST_URL, myAxios)
}

const trainGateInService = {
  createTrain: (onTerminal: boolean, trainDto: TrainDto): AxiosPromise<TrainDto> => {
    return api.trainGateInCreateTrainGateIn(onTerminal, cleanObject(trainDto))
  },

  createTrainModel: (trainId: number, file: File) => {
    return api.trainGateInImportUpdateTrainIn(trainId, file)
  },

  updateTrainModel: (trainId: number, file: File) => {
    return api.trainGateInImportUpdateTrainIn(trainId, file)
  },

  storeContainersOnTrain: (trainId: number, storeParams: TransitionStoreDto) => {
    return api.trainGateInStoreContainers(trainId, cleanObject(storeParams))
  },

  loadOnWagon: (wagonId: number, order: number, trainDto: TransitionDto) => {
    return api.trainGateInLoadOnWagon(wagonId, order, cleanObject(trainDto))
  },

  loadPlannedOnWagon: (
    wagonId: number,
    order: number,
    transitionId: number,
    qualityType: TransitionSearchListDtoQualityTypeEnum | undefined
  ) => {
    if (qualityType) {
      return api.trainGateInLoadPlannedOnWagon(wagonId, order, transitionId, qualityType)
    } else {
      return api.trainGateInLoadPlannedOnWagon(wagonId, order, transitionId)
    }
  },

  removeWagon: (trainId: number, wagonId: number): AxiosPromise<TrainDto> => {
    return api.trainGateInRemoveWagon(trainId, wagonId)
  },

  getGateInTrainView: (page?: number, size?: number, sort?: string[]): AxiosPromise<PageTrainViewDto> => {
    return api.trainGateInGetTrainView(page, size, sort)
  },
}

export default trainGateInService
